import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import '../../app.css';
import '../AdsList/AdsList.css'
import Header from '../../Components/Header/Header';
import { useNavigate, useLocation } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { deviceLocale, URLS, usePageTracking } from '../../utilities/constants';
import axios from 'axios';
import { DataContext } from '../../utilities/DataContext';
import { ClipLoader } from "react-spinners";
import AlertComponent from "../../Components/AlertComponent";
import { debounce } from 'lodash';
import ReactGA from 'react-ga4';
import { useAnalytics } from '../../utilities/AnalyticsContext';

const AdsList = () => {
    const { markTitleUpdated } = useAnalytics()
    const navigate = useNavigate();
    const location = useLocation();
    const [activeAdds, setActiveAdds] = useState([])
    const [InprogressAdds, setInprogressAdds] = useState([])
    const { setPageTitleAvailable, token, addDetails, setAddDetails, userType, setUserType, setToken, setIsLoggedIn, setUserData, setCompanyInfo, setMessageCount, setChatData, setTab, setNoOfBedErr } = useContext(DataContext);
    const [loading, setLoading] = useState(false);
    const adType = location.state?.adType;

    const pageTitle = 'Alle Anzeigen auf campervanspot.com'
    const previousTitleRef = useRef();

    useEffect(() => {
        document.title = 'Alle Anzeigen auf campervanspot.com'
        // markTitleUpdated(pageTitle)
    }, [])




    const sendAnalyticsEvent = debounce((title) => {
        if (previousTitleRef.current !== title) {
            console.log('Sending pageview event to Google Analytics:', title);
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title });
            setPageTitleAvailable(true)
            previousTitleRef.current = title;
        }
    }, 500);



    // useEffect(() => {
    //     document.title = 'Alle Anzeigen auf campervanspot.com'
    //     if (window.trackPageTitleChange) {
    //         window.trackPageTitleChange('Alle Anzeigen auf campervanspot.com');
    //     }
    // }, [])


    useEffect(() => {
        console.log('adType', adType)
        callAddsListApi()
    }, []);


    const formatCurrency = (value, currency) => {
        console.log('currency', currency)
        if (value !== '' && currency !== '' && value !== null && currency !== null) {
            let locale = 'de-DE'; // German locale for both EUR and CHF
            let formattedValue = new Intl.NumberFormat(deviceLocale, {
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(value);
            return `${formattedValue} ${currency.toUpperCase()}`;
        } else {
            return ''
        }

    };



    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${day}.${month}`;
    };

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const createAddBtnClick = () => {
        setAddDetails({})
        navigate('/adcreate')
    }

    const callAddsListApi = async () => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.ADDS_LIST}`;
        await axios.get(url, { headers: { 'Authorization': token } })
            .then((response) => {
                setLoading(false)
                if (response?.data.status === 1) {
                    const data = response.data.data
                    const activeads = data.filter(add => add.publish_status === 'published');
                    const inactiveAdds = data.filter(add => add.publish_status !== 'published');

                    // if (adType === undefined) {
                    //     setActiveAdds(activeads)
                    //     setInprogressAdds(inactiveAdds)
                    // } else if (adType === 'inactive') {
                    //     setInprogressAdds(inactiveAdds)
                    // } else if (adType === 'active') {
                    //     setActiveAdds(activeads)
                    // }
                    setActiveAdds(activeads)
                    setInprogressAdds(inactiveAdds)
                } else {
                    console.log('response', response);
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log('error', error);
                if ((error && error.response.data && error.response.data.message)) {
                    if (error.response.data.message === 'Token fehlt!' || error.response.data.message === 'Der Token ist abgelaufen. Bitte melden Sie sich erneut an.' || error.response.data.message === 'Ungültiges Token. Bitte melden Sie sich erneut an.' || error.response.data.message === 'Benutzer nicht gefunden.') {
                        setIsLoggedIn(false)
                        setUserData({})
                        setCompanyInfo({})
                        setUserType('')
                        setTab('')
                        setNoOfBedErr('')
                        setMessageCount('')
                        setToken('')
                        setChatData([])
                        setAddDetails({})
                    }
                }
            });
    }

    const handlePublishClick = (addStatus, adId, add) => {
        if (add.status === 'incomplete' && add.vehicle_ad_feature_technical_data_status === 'incomplete' && add.vehicle_basic_technical_data_status === 'incomplete') {
            // navigate('/adcreate', { state: { id: add.id } })
        } else {
            // const status = addDetails.vehicle_ad_overview_data.publish_status
            let status = addStatus === 'inprogress' ? 'publish' : addStatus === 'published' ? 'unpublish' : addStatus === 'unpublished' ? 'republish' : ''
            callAddPublishApi(status, adId)
        }
    }

    const callAddPublishApi = async (status, adId) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.ADD_PUBLISH}${adId}/?publish_status=${status}`;
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";
        await axios
            .get(url, { headers: { Authorization: token } })
            .then((response) => {
                console.log("response", response);
                setLoading(false);
                if (response?.data.status === 1) {
                    callAddsListApi(token)
                } else {
                    console.log("response", response);
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                setLoading(false);
            });
    }

    return (
        <>
            <Header />
            {loading && (
                <div className="loader">
                    <ClipLoader
                        color='orange'
                        loading={loading}
                        size={50}
                    />
                </div>
            )}
            <div style={{ height: "100vh" }}>
                <div className='maindiv'>
                    <Row style={{ marginTop: '2vmin' }}>
                        {activeAdds.length > 0 &&
                            <Col md={10} lg={7} xxl={6} xl={6} xs={12} sm={12} >
                                <div>
                                    <h2 className="headertext_2  mt3 mb4" style={{ marginLeft: 10 }}>
                                        Aktive Anzeigen
                                    </h2>
                                    <div className='adds_section_cards'>
                                        {activeAdds.map(add => (
                                            <Row className='card_content row_add' id={`${add.id}`} onClick={() => {
                                                setAddDetails({})
                                                navigate('/adcreate', { state: { id: add.id } })
                                            }}>
                                                <Col md={3} xs={3} xl={2} xxl={2} lg={3} sm={3} className='col_add' style={{ borderRadius: 10, display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 10, justifyContent: 'center' }}>
                                                    <img style={{ width: '100%', objectFit: 'contain', borderRadius: 10, maxHeight: 80, borderRadius: 10 }} src={add.hero_image_2k !== null ? add.hero_image_2k : require('../../assets/add_placeholder.jpeg')} />
                                                    <button className="ad_unpublish_btn" onClick={(e) => {
                                                        e.stopPropagation();
                                                        handlePublishClick(add.publish_status, add.id, add);
                                                    }}> <span>Pausieren</span></button>
                                                </Col>
                                                <Col md={8} xxl={9} xl={9} lg={8} sm={8} xs={8} className='col_add' style={{ marginLeft: 5, marginRight: 5 }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>
                                                        <span className='add_name'>{add.vehicle_name}</span>
                                                        {(add.publish_date !== null && add.end_date !== null) && <span className='add_date' style={{ marginLeft: 10, marginRight: 10 }}>{`${formatDateTime(add.publish_date)}-${formatDateTime(add.end_date)}`}</span>}
                                                        {/* <span className='add_date' style={{ marginLeft: 10 }}>01.02 - 30.03</span> */}
                                                        <span className='add_name' style={{ marginRight: 10 }}>{formatCurrency(add.price, add.currency)}</span>
                                                    </div>
                                                    <Row style={{ marginBottom: 10, marginLeft: 10, marginRight: 10 }}>
                                                        <Col xxl={3} xl={5} lg={5} md={5} xs={5} className='col_add'>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                <span className='add_name'>{add.ad_analytics_data.impressions}</span>
                                                                <span className='add_date'>Impressionen</span>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={3} xl={5} md={5} lg={5} xs={5} className='col_add'>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                <span className='add_name'>{add.ad_analytics_data.active_users}</span>
                                                                <span className='add_date'>Aktive Benutzer</span>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={5} xl={9} md={7} lg={8} xs={10} className='col_add'>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                <span className='add_name'>{Math.round(add.ad_analytics_data.average_session_duration)}</span>
                                                                <span className='add_date'>Durchschnittliche Sitzungsdauer</span>
                                                                <span style={{ fontSize: 13 }}>(in Sekunden)</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                    <button className="add_btn2" onClick={createAddBtnClick}>+ Anzeige erstellen</button>
                                </div>
                            </Col>
                        }
                        {InprogressAdds.length > 0 &&
                            <Col md={10} lg={7} xxl={6} xl={6} xs={12} sm={12}>
                                {
                                    <div>
                                        <h2 className="headertext_2  mt3 mb4" style={{ marginLeft: 10 }}>
                                            Inaktive Anzeigen
                                        </h2>
                                        <div className='adds_section_cards'>
                                            {InprogressAdds.map(add => (
                                                <div className='card_content' >
                                                    <Row className='row_add' id={`${add.id}`} onClick={() => {
                                                        setAddDetails({})
                                                        navigate('/adcreate', { state: { id: add.id } })
                                                    }}>
                                                        <Col md={3} xs={3} xl={2} xxl={2} lg={3} sm={3} className='col_add' style={{ borderRadius: 10, display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 10, justifyContent: 'center' }}>
                                                            <img style={{ width: '100%', objectFit: 'contain', borderRadius: 10, maxHeight: 80, borderRadius: 10 }} src={add.hero_image_2k !== null ? add.hero_image_2k : require('../../assets/add_placeholder.jpeg')} />
                                                            {((add.publish_status == 'inprogress' || add.publish_status == 'unpublished') && add.status === 'completed' && add.vehicle_basic_technical_data_status === 'completed' && add.vehicle_ad_feature_technical_data_status === 'completed') &&
                                                                <button className="publish_btn" onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handlePublishClick(add.publish_status, add.id, add)
                                                                }}> <span>{add.publish_status == 'inprogress' ? 'Veröffentlichen' : add.publish_status == 'unpublished' ? 'Erneut veröffentlichen' : ''}</span></button>
                                                            }

                                                        </Col>
                                                        <Col md={8} xxl={9} xl={9} lg={8} sm={8} xs={8} className='col_add' style={{ marginLeft: 5, marginRight: 5 }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>
                                                                <span className='add_name'>{add.vehicle_name}</span>
                                                                {(add.updated_datetime !== null) && <span className='add_date' style={{ marginLeft: 10, marginRight: 10 }}>{`${formatDateTime(add.updated_datetime)}`}</span>}
                                                                {/* <span className='add_date' style={{ marginLeft: 10 }}>01.02 - 30.03</span> */}
                                                                <span className='add_name' style={{ marginRight: 10 }}>{formatCurrency(add.price, add.currency)}</span>
                                                            </div>
                                                            <Row style={{ marginBottom: 10, marginLeft: 10, marginRight: 10 }}>
                                                                <Col xxl={3} xl={5} lg={5} md={5} xs={5} className='col_add'>
                                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                        <span className='add_name'>{add.ad_analytics_data.impressions}</span>
                                                                        <span className='add_date'>Impressionen</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xxl={3} xl={5} md={5} lg={5} xs={5} className='col_add'>
                                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                        <span className='add_name'>{add.ad_analytics_data.active_users}</span>
                                                                        <span className='add_date'>Aktive Benutzer</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xxl={5} xl={9} md={7} lg={8} xs={10} className='col_add'>
                                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                        <span className='add_name'>{Math.round(add.ad_analytics_data.average_session_duration)}</span>
                                                                        <span className='add_date'>Durchschnittliche Sitzungsdauer</span>
                                                                        <span style={{ fontSize: 13 }}>(in Sekunden)</span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                    <Row style={{ paddingLeft: 10, paddingBottom: 10 }}>
                                                        <Col>
                                                            {((add.publish_status == 'inprogress' || add.publish_status == 'unpublished') && (add.status === 'incomplete' || add.vehicle_basic_technical_data_status === 'incomplete' || add.vehicle_ad_feature_technical_data_status === 'incomplete')) &&
                                                                <span className='incompletetext'>Unvollständige Daten, bitte vervollständigen Sie diese.</span>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                        </div>
                                        <button className="add_btn2" onClick={createAddBtnClick}>+ Anzeige erstellen</button>
                                    </div>
                                }
                            </Col>
                        }
                        {activeAdds.length == 0 && InprogressAdds.length == 0 &&
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', opacity: (activeAdds.length == 0 && InprogressAdds.length == 0) ? 1 : 0 }}>
                                <p style={{ textAlign: 'center' }}>
                                    Du hast noch keine Anzeige aktiv!
                                    Erstelle deine erste Anzeige und begeistere deine Kunden.
                                </p>
                                <button className="create_addbtn" onClick={createAddBtnClick}>+ Anzeige erstellen</button>
                            </div>
                        }

                    </Row>
                </div>
            </div >
        </>
    )
}

export default AdsList;