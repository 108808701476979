import React, { useState, useRef, useEffect, useContext } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import '../../app.css'
import '../Ads/Ads.css';
import { formatNumberFromLocale, newtaburl, Strings, URLS, formatNumberToLocale, deviceLocale } from '../../utilities/constants';
import 'quill/dist/quill.snow.css'
import DropDownInput from '../../Components/DropDownInput/DropDownInput';
import { DataContext } from '../../utilities/DataContext';
import axios from 'axios';
import MeasurementInput from '../../Components/MeasurementInput/MeasurementInput';
import TopLabelInput from '../../Components/TpLabelInput/TopLabelInput'
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from "react-spinners";
import AlertComponent from "../../Components/AlertComponent";

const AdBasicDetails = ({ onBackPress, onNextPress, tabchanged, onClick }) => {
    const navigate = useNavigate();

    const [basetabOpened, setBaseTabOpend] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [loading, setLoading] = useState(false);
    const { userData, token, addDetails, setAddDetails, tab, setNoOfBedErr } = useContext(DataContext);
    const [descriptiopn, setDescription] = useState('');
    const [error, setError] = useState('')
    const [showalert, setShowAlert] = useState(false)
    const [typeData, setTypeData] = useState(['Kastenwagen', 'Teilintegriert', 'Vollintegriert'])
    const [manfacturerData, setManufacturerData] = useState([])
    const [modelData, setModelData] = useState([])
    const [vehicleAvailabilityData, setVehicleAvailabilityData] = useState(['Verfügbar', 'Reserviert'])
    // const [vehicleAvailabilityData, setVehicleAvailabilityData] = useState(['Reserviert', 'Verfügbar', 'Auf Bestellung', 'Verkauft'])
    const [fuelTypeData, setFuelTypeData] = useState(['Benzin', 'Diesel', 'Elektro', 'Autogas (LPG)', 'Erdgas (CNG)', 'Hybrid (Benzin/Elektro)', 'Hybrid (Diesel/Elektro)', 'Ethanol (FFV, E85 etc)', 'Wasserstoff', 'Andere'])
    const [gearBoxType, setGearBoxType] = useState(['Manuell', 'Automatisch'])
    const [emissionClass, setEmissionClass] = useState(['Euro1', 'Euro2', 'Euro3', 'Euro4', 'Euro5', 'Euro6', 'Euro6c', 'Euro6d', 'Euro6d-TEMP'])
    const [environmentalBadge, setEnvironmentalBadge] = useState(['Keine', 'Rot', 'Gelb', 'Grün'])
    const [airConditioning, setAirConditioning] = useState(['Ja', 'Nein'])
    const [parkingAid, setParckingAid] = useState(['Ja', 'Nein'])
    const [originalColor, setOriginalColor] = useState([])
    const [color, setColor] = useState(['Beige', 'Blau', 'Braun', 'Gelb', 'Gold', 'Grau', 'Grün', 'Orange', 'Rot', 'Schwarz', 'Silber', 'Violett', 'Weiß', 'Metallisch'])
    const [euipemnttInputCount, setEquipemntInputCount] = useState(5);
    const [addStatus, setAddStatus] = useState('')
    const [formData, setFormData] = useState({
        type: '',
        manfacturer: '',
        model: '',
        availability: '',
        mileage: "",
        vehicleNumber: "",
        yearOfManfacture: "",
        fuelType: '',
        gearBoxType: '',
        noofsetas: "",
        performance: "",
        emissionClass: '',
        environmentalBadge: '',
        maxweight: "",
        airConditioning: '',
        parkingaid: '',
        OriginalColor: "",
        color: '',
        length: "",
        width: "",
        height: "",
        equipments: [],
        particleFilter: "Nein",
        startstop: "Nein",
        fuleConsumptionCombined: "",
        fuleConsumptionUrban: "",
        fuleConsumptionExtraUrban: "",
        co2emissioncombined: ""
    });


    const [formdataError, setFormDataError] = useState({
        type: '',
        manfacturer: '',
        mileage: "",
        yearOfManfacture: "",
        noofsetas: "",
    });

    const formDataRef = useRef(formData);
    const formDataErrRef = useRef(formdataError);

    useEffect(() => {
        setBaseTabOpend(true)
    }, [basetabOpened]);


    useEffect(() => {
        formDataRef.current = formData;
    }, [formData]);

    useEffect(() => {
        formDataErrRef.current = formdataError;
    }, [formdataError]);

    useEffect(() => {
        if (basetabOpened !== true) {
            callBasicDetailsistApi()
            if (!isEmptyObject(addDetails) && addDetails.vehicle_ad_overview_data.id) {
                callAddDetailApi(addDetails.vehicle_ad_overview_data.id)
            }
        }
        return () => {
            console.log('tabchangedbaseVehicle', tab)
            if (tab !== 'baseVehicle' && tab !== '') {
                setBaseTabOpend(false)
                handleNextClick('tabchange')
            }
        };

    }, [tab]);


    function convertGermanToEnglish(input) {
        // Remove thousand separators (dots in German format)
        let sanitizedInput = input.replace(/\./g, '');
        // Replace the decimal comma with a dot
        sanitizedInput = sanitizedInput.replace(/,/g, '.');
        return sanitizedInput;
    }

    const handleDropdownToggle = (label) => {
        console.log('label', label)
        console.log('openDropdown', openDropdown)
        setOpenDropdown(openDropdown === label ? null : label);
    };


    const handleCheckboxChange = (type) => {
        if (type === 'filter') {
            setFormData(prevFormData => ({
                ...prevFormData,
                particleFilter: formData.particleFilter === 'Ja' ? 'Nein' : 'Ja'
            }));
        } else if (type === 'start-stop') {
            setFormData(prevFormData => ({
                ...prevFormData,
                startstop: formData.startstop === 'Ja' ? 'Nein' : 'Ja'
            }));
        }
    };


    const validateFormData = () => {
        const errors = {};
        const currentYear = new Date().getFullYear(); // Get the current year
        const maxYear = currentYear + 1; // One year after the current year
        console.log('mileage', formDataRef.current.mileage)
        if (formDataRef.current.type === undefined || formDataRef.current.type === "" || formDataRef.current.type === null) {
            errors.type = "Bitte füllen Sie dieses Pflichtfeld aus.";
        }
        if (formDataRef.current.manfacturer === undefined || formDataRef.current.manfacturer === "") {
            errors.manfacturer = "Bitte füllen Sie dieses Pflichtfeld aus.";
        }
        if (formDataRef.current.mileage === undefined || formDataRef.current.mileage === "" || formDataRef.current.mileage === 0 || formDataRef.current.mileage === null) {
            errors.mileage = "Bitte füllen Sie dieses Pflichtfeld aus.";
        }
        if (formDataRef.current.yearOfManfacture === undefined || formDataRef.current.yearOfManfacture === "" || formDataRef.current.yearOfManfacture === null) {
            errors.yearOfManfacture = "Bitte füllen Sie dieses Pflichtfeld aus.";
        } else {
            const yearValue = parseInt(formDataRef.current.yearOfManfacture, 10);
            if (isNaN(yearValue) || yearValue < 1900 || yearValue > maxYear) {
                errors.yearOfManfacture = `Das Jahr muss zwischen 1900 und ${maxYear} liegen.`;
            }
        }
        if (formDataRef.current.noofsetas === undefined || formDataRef.current.noofsetas === "" || formDataRef.current.noofsetas === null) {
            errors.noofsetas = "Bitte füllen Sie dieses Pflichtfeld aus.";
        }
        return errors;
    }

    const handlePublishClick = () => {
        const techdata = !isEmptyObject(addDetails) && !isEmptyObject(addDetails.vehicle_ad_feature_technical_data)
        const bedsizes = addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[1] && (addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[1].technical_data.double_bed_specifications.length > 0 || addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[1].technical_data.single_bed_specifications.length > 0)
        console.log('techdata', techdata, 'bedsizes', bedsizes)
        if (techdata && bedsizes) {
            const status = addDetails.vehicle_ad_overview_data.publish_status
            handleNextClick('publish')
        } else {
            setNoOfBedErr("Bitte füllen Sie dieses Pflichtfeld aus.")
            handleBackClick()
        }


    }

    const handleBackClick = () => {
        if (!isEmptyObject(addDetails)) {
            handleNextClick('back')
        } else {
            onBackPress()
        }
    }

    const handleNextClick = (btnAction) => {
        const errors = validateFormData();
        setFormDataError(errors);
        if (Object.keys(errors).length === 0) {
            if (!isEmptyObject(addDetails)) {
                if (!isEmptyObject(addDetails.vehicle_basic_technical_data)) {
                    callAdBasicDetailsUpdateApi(btnAction)
                } else {
                    callAddsBasicDetailsCreateApi(btnAction)
                }
            }
        } else {
            return
        }

    }

    const addEquipment = () => {
        setEquipemntInputCount(prevCount => prevCount + 1)
        setFormData(prevFormData => ({
            ...prevFormData,
            equipments: [...prevFormData.equipments, ""]
        }));
    }

    const deleteEquipment = (index) => {
        setFormData(prevFormData => {
            const updatedEquipments = prevFormData.equipments.filter((_, i) => i !== index);

            return {
                ...prevFormData,
                equipments: updatedEquipments
            };
        });
        setEquipemntInputCount(prevCount => prevCount - 1); // Decrement input count
    };

    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0;
    };

    const handleInputChange = (label, value) => {
        console.log('label, value', label, value)

        setFormData(prevFormData => {
            const updatedFormData = {
                ...prevFormData,
                [label]: value
            };
            if (label === 'manfacturer') {
                callModelsApi(value)
            }
            console.log('Updated form data:', updatedFormData);
            return updatedFormData;
        });


    }

    const formatAddName = (name) => {
        return name
            .toLowerCase()                          // Convert to lowercase
            .replace(/[^a-z0-9]+/g, '-')            // Replace non-alphanumeric characters with a hyphen
            .replace(/^-+|-+$/g, '');               // Remove leading or trailing hyphens
    };

    const openNewTab = () => {
        // Check if running in development or production
        const formattedAddName = formatAddName(addDetails.vehicle_ad_overview_data.vehicle_name);
        // const baseUrl =
        //     process.env.NODE_ENV === "development"
        //         ? URLS.APP_LOCAL_URL
        //         : URLS.APP_STAGIN_URL;
        // const formattedCompanyName = formatCompanyName(userData.company_name);
        const baseUrl = newtaburl
        const componentPage = `ad/${addDetails.vehicle_ad_overview_data.id}`;
        const url = baseUrl + componentPage;
        window.open(url, "_blank");
    };


    const handleEquipmentInputChange = (index, value) => {
        setFormData(prevFormData => {
            const updatedEquipments = [...prevFormData.equipments];
            updatedEquipments[index] = value;

            return {
                ...prevFormData,
                equipments: updatedEquipments
            };
        });
        console.log('updatedEquipments', formData.equipments)
    };

    const callBasicDetailsistApi = async () => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.BASIC_DETAILS_LIST}`;
        await axios.get(url, { headers: { 'Authorization': token } })
            .then((response) => {
                setLoading(false)
                console.log('response', response);
                if (response?.data.status === 1) {
                    if (response.data.data.manufacturers.length > 0) {
                        setManufacturerData(response.data.data.manufacturers)
                    }
                    if (response.data.data.originalColors.length > 0) {
                        console.log('response.data.data.OriginalColors', response.data.data.originalColors)
                        setOriginalColor(response.data.data.originalColors)
                    }
                } else {
                    console.log('response', response);
                    setShowAlert(true)
                    setError(response.data.message)
                }
            })
            .catch(function (error) {
                console.log('error', error);
                setLoading(false)
                if (error.response) {
                    setShowAlert(true)
                    setError(error.response.data.message)
                }
            });
    }

    const callModelsApi = async (manufacturer) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.MODELS_LIST}${manufacturer}/`;
        await axios.get(url, { headers: { 'Authorization': token } })
            .then((response) => {
                setLoading(false)

                if (response?.data.status === 1) {
                    setModelData(response.data.models)
                    if (response.data.models.length > 0) {
                        console.log('response', response.data.models[0]);
                        const modal = response.data.models[0]
                        // setFormData({ model: model })
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            model: modal
                        }));
                    }
                } else {
                    console.log('response', response);
                    setShowAlert(true)
                    setError(response.data.message)
                }
            })
            .catch(function (error) {
                console.log('error', error);
                setLoading(false)
                if (error.response) {
                    setShowAlert(true)
                    setError(error.response.data.message)
                }
            });
    }

    const callAddDetailApi = async (addId) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.FULL_ADD_DETAILS}${addId}`;
        await axios.get(url, { headers: { 'Authorization': token } })
            .then((response) => {
                setLoading(false)
                console.log('response', response);
                if (response?.data.status === 1) {
                    setAddDetails(response.data)
                    setAddStatus(response.data.vehicle_ad_overview_data.publish_status)
                    let basictechnicalobject = response.data.vehicle_basic_technical_data
                    if (!isEmptyObject(basictechnicalobject)) {
                        setFormData({
                            type: basictechnicalobject.type, manfacturer: basictechnicalobject.manufacturer, model: basictechnicalobject.model, availability: basictechnicalobject.availability,
                            mileage: basictechnicalobject !== null && (basictechnicalobject.mileage_km !== null || basictechnicalobject.mileage_km !== '') ? formatNumberToLocale(basictechnicalobject.mileage_km) : '',
                            vehicleNumber: basictechnicalobject.vehicle_number,
                            yearOfManfacture: basictechnicalobject.year_of_manufacture,
                            fuelType: basictechnicalobject.fuel_type,
                            gearBoxType: basictechnicalobject.gearbox,
                            performance: basictechnicalobject.power_hp,
                            emissionClass: basictechnicalobject.emission_class,
                            environmentalBadge: basictechnicalobject.environmental_sticker,
                            maxweight: basictechnicalobject !== null && (basictechnicalobject.vehicle_total_weight_kg !== null || basictechnicalobject.vehicle_total_weight_kg !== '') ? formatNumberToLocale(basictechnicalobject.vehicle_total_weight_kg) : '',
                            airConditioning: basictechnicalobject.air_conditioning,
                            parkingaid: basictechnicalobject.parking_aid,
                            OriginalColor: basictechnicalobject.original_color,
                            color: basictechnicalobject.color,
                            length: basictechnicalobject !== null && (basictechnicalobject.length_cm !== null || basictechnicalobject.length_cm !== '') ? formatNumberToLocale(basictechnicalobject.length_cm) : '',
                            width: basictechnicalobject !== null && (basictechnicalobject.width_cm !== null || basictechnicalobject.width_cm !== '') ? formatNumberToLocale(basictechnicalobject.width_cm) : '',
                            height: basictechnicalobject !== null && (basictechnicalobject.height_cm !== null || basictechnicalobject.height_cm !== '') ? formatNumberToLocale(basictechnicalobject.height_cm) : '',
                            noofsetas: basictechnicalobject.no_of_seats,
                            equipments: basictechnicalobject.equipments,
                            particleFilter: basictechnicalobject.particle_filter,
                            startstop: basictechnicalobject.automatic_start_or_stop,
                            fuleConsumptionCombined: basictechnicalobject.fuel_consumption_combined_l_per_100km ? formatNumberToLocale(basictechnicalobject.fuel_consumption_combined_l_per_100km) : '',
                            fuleConsumptionUrban: basictechnicalobject.fuel_consumption_urban_l_per_100km ? formatNumberToLocale(basictechnicalobject.fuel_consumption_urban_l_per_100km) : '',
                            fuleConsumptionExtraUrban: basictechnicalobject.fuel_consumption_extra_urban_l_per_100km ? formatNumberToLocale(basictechnicalobject.fuel_consumption_extra_urban_l_per_100km) : '',
                            co2emissioncombined: basictechnicalobject.co2_emissions_combined_g_per_km ? formatNumberToLocale(basictechnicalobject.co2_emissions_combined_g_per_km) : ''
                        })

                        setEquipemntInputCount((basictechnicalobject.equipments.length > 0 && basictechnicalobject.equipments.length <= 5) ? 5 : (basictechnicalobject.equipments.length >= 5) ? basictechnicalobject.equipments.length : 5)
                    }
                } else {
                    console.log('response', response);
                    setShowAlert(true)
                    setError(response.data.message)
                }
            })
            .catch(function (error) {
                console.log('error', error);
                setLoading(false)
                if (error.response) {
                    setShowAlert(true)
                    setError(error.response.data.message)
                }
            });
    }

    const parseAnyLocaleNumber = (value) => {
        if (!value) return "";

        // Detect separators dynamically
        const decimalSeparator = value.match(/[.,](\d{1,2})$/)?.[0]?.[0]; // Find decimal part
        const thousandSeparator = decimalSeparator === "," ? "." : ","; // Assume the opposite separator

        // Remove thousand separators and replace the decimal separator with '.'
        const normalizedValue = value
            .replace(new RegExp(`\\${thousandSeparator}`, "g"), "") // Remove thousand separator
            .replace(new RegExp(`\\${decimalSeparator}`), "."); // Replace decimal separator with "."

        return parseFloat(normalizedValue) || 0;
    };

    // Utility function to convert locale-specific decimal separator to English decimal separator
    const convertToEnglishDecimal = (value) => {
        const result = parseAnyLocaleNumber(value);
        return result.toFixed(2)

    };

    const formatNumberToLocale = (value) => {
        if (typeof value === 'number' || (typeof value === 'string' && value.trim() !== '')) {
            const numberFormat = new Intl.NumberFormat(deviceLocale);
            return numberFormat.format(value);
        }
        return value;
    };

    const prepareNumberForSubmission = (formattedValue) => {
        if (typeof formattedValue === 'string') {
            // Remove any non-numeric characters except for the decimal point
            const plainNumberString = formattedValue.replace(/[^0-9.-]/g, '');
            return parseFloat(plainNumberString);
        }
        return formattedValue;
    };
    const callAddsBasicDetailsCreateApi = async (btnAction) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.CREATE_TECHNICLE_DATA}`;
        const form = new FormData();
        const formdata = formDataRef.current

        console.log('formData.fuleConsumptionExtraUrban', formdata.fuleConsumptionExtraUrban)

        form.append('status', 'completed')
        form.append('vehicle_ad_overview', addDetails.vehicle_ad_overview_data.id)
        form.append('type', formdata.type !== 'undefined' && formdata.type !== null ? formdata.type : '')
        form.append('manufacturer', formdata.manfacturer !== 'undefined' && formdata.manfacturer !== null ? formdata.manfacturer : '')
        form.append('model', formdata.model !== 'undefined' ? formdata.model : '')
        form.append('availability', formdata.availability !== 'undefined' && formdata.availability !== null ? formdata.availability : '')
        form.append('mileage_km', formdata.mileage !== null && formdata.mileage !== '' && formdata.mileage !== "0" ? formatNumberFromLocale(formdata.mileage) : '')
        form.append('vehicle_number', formdata.vehicleNumber !== null && formdata.vehicleNumber !== "undefined" ? formdata.vehicleNumber : '')
        form.append('year_of_manufacture', formdata.yearOfManfacture !== null ? formdata.yearOfManfacture : '')
        form.append('fuel_type', formdata.fuelType !== 'undefined' && formdata.fuelType !== null ? formdata.fuelType : '')
        form.append('gearbox', formdata.gearBoxType !== 'undefined' && formdata.gearBoxType !== null ? formdata.gearBoxType : '')
        form.append('power_hp', formdata.performance !== null ? formdata.performance : '')
        form.append('emission_class', formdata.emissionClass !== 'undefined' && formdata.emissionClass !== null ? formdata.emissionClass : '')
        form.append('environmental_sticker', (formdata.environmentalBadge !== 'undefined' && formdata.environmentalBadge !== null) ? formdata.environmentalBadge : '')
        form.append('particle_filter', formdata.particleFilter !== "undefined" ? formdata.particleFilter : false)
        form.append('automatic_start_or_stop', formdata.startstop !== "undefined" ? formdata.startstop : false)
        // Usage in your form data preparation
        form.append('fuel_consumption_combined_l_per_100km', formdata.fuleConsumptionCombined ? convertToEnglishDecimal(formdata.fuleConsumptionCombined) : '');
        form.append('fuel_consumption_urban_l_per_100km', formdata.fuleConsumptionUrban ? convertToEnglishDecimal(formdata.fuleConsumptionUrban) : '');
        form.append('fuel_consumption_extra_urban_l_per_100km', formdata.fuleConsumptionExtraUrban ? convertToEnglishDecimal(formdata.fuleConsumptionExtraUrban) : '');
        form.append('co2_emissions_combined_g_per_km', formdata.co2emissioncombined ? convertToEnglishDecimal(formdata.co2emissioncombined) : '');
        form.append('vehicle_total_weight_kg', formdata.maxweight !== null && formdata.maxweight !== '' && formdata.maxweight !== "0" ? formatNumberFromLocale(formdata.maxweight) : '');
        form.append('air_conditioning', formdata.airConditioning !== 'undefined' && formdata.airConditioning !== null ? formdata.airConditioning : '')
        form.append('parking_aid', (formdata.parkingaid !== 'undefined' && formdata.parkingaid !== null) ? formdata.parkingaid : '')
        form.append('original_color', formdata.OriginalColor !== null && formdata.OriginalColor !== 'undefined' ? formdata.OriginalColor : '')
        form.append('color', formdata.color !== 'undefined' && formdata.color !== null ? formdata.color : '')
        form.append('length_cm', formdata.length !== null && formdata.length !== '' && formdata.length !== "0" ? formatNumberFromLocale(formdata.length) : '')
        form.append('width_cm', formdata.width !== null && formdata.width !== '' && formdata.width !== "0" ? formatNumberFromLocale(formdata.width) : '')
        form.append('height_cm', formdata.height !== null && formdata.height !== '' && formdata.height !== "0" ? formatNumberFromLocale(formdata.height) : '')
        form.append('no_of_seats', formdata.noofsetas !== null ? formdata.noofsetas : '')
        form.append('equipments', JSON.stringify(formdata.equipments))

        const formDataObject = {};
        form.forEach((value, key) => {
            formDataObject[key] = value;
        });

        console.log('formDataObject', formDataObject);
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
        await axios.post(url, form, { headers: { 'Authorization': token } })
            .then((response) => {
                setLoading(false)
                console.log('response', response);
                if (response?.data.status === 1) {
                    callAddDetailApi(addDetails.vehicle_ad_overview_data.id)
                    if (btnAction === 'publish') {
                        let status = addStatus === 'inprogress' ? 'publish' : addStatus === 'published' ? 'unpublish' : addStatus === 'unpublished' ? 'republish' : ''
                        callAddPublishApi(status)
                    } else if (btnAction == 'next') {
                        navigate(`/ad/${addDetails.vehicle_ad_overview_data.id}`)
                    } else if (btnAction == 'preview') {
                        openNewTab()
                    } else if (btnAction == 'back') {
                        onBackPress()
                    } else if (btnAction == 'tabchange') {
                        onClick()
                    }
                } else {
                    console.log('response', response);
                    setShowAlert(true)
                    setError(response.data.message)
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log('error', error);
                if (error.response) {
                    setShowAlert(true)
                    setError(error.response.data.message)
                }
            });

    }

    const callAdBasicDetailsUpdateApi = async (btnAction) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.UPDATE_TECHNICLE_DATA}${addDetails.vehicle_ad_overview_data.id}/`;
        const form = new FormData();
        const formdata = formDataRef.current
        form.append('status', 'completed')
        console.log('fuel_consumption_urban_l_per_100km::', (formdata.fuleConsumptionExtraUrban === "undefined"))
        form.append('vehicle_ad_overview', addDetails.vehicle_ad_overview_data.id)
        form.append('type', formdata.type !== 'undefined' && formdata.type !== null ? formdata.type : '')
        form.append('manufacturer', formdata.manfacturer !== 'undefined' && formdata.manfacturer !== null ? formdata.manfacturer : '')
        form.append('model', formdata.model !== 'undefined' ? formdata.model : '')
        form.append('availability', formdata.availability !== 'undefined' && formdata.availability !== null ? formdata.availability : '')
        form.append('mileage_km', formdata.mileage !== null && formdata.mileage !== '' && formdata.mileage !== "0" ? formatNumberFromLocale(formdata.mileage) : '')
        form.append('vehicle_number', formdata.vehicleNumber !== null && formdata.vehicleNumber !== "undefined" ? formdata.vehicleNumber : '')
        form.append('year_of_manufacture', formdata.yearOfManfacture !== null ? formdata.yearOfManfacture : '')
        form.append('fuel_type', formdata.fuelType !== 'undefined' && formdata.fuelType !== null ? formdata.fuelType : '')
        form.append('gearbox', formdata.gearBoxType !== 'undefined' && formdata.gearBoxType !== null ? formdata.gearBoxType : '')
        form.append('power_hp', formdata.performance !== null ? formdata.performance : '')
        form.append('emission_class', formdata.emissionClass !== 'undefined' && formdata.emissionClass !== null ? formdata.emissionClass : '')
        form.append('environmental_sticker', (formdata.environmentalBadge !== 'undefined' && formdata.environmentalBadge !== null) ? formdata.environmentalBadge : '')
        form.append('particle_filter', formdata.particleFilter !== "undefined" ? formdata.particleFilter : false)
        form.append('automatic_start_or_stop', formdata.startstop !== "undefined" ? formdata.startstop : false)
        form.append('fuel_consumption_combined_l_per_100km', formdata.fuleConsumptionCombined ? convertToEnglishDecimal(formdata.fuleConsumptionCombined) : '');
        form.append('fuel_consumption_urban_l_per_100km', formdata.fuleConsumptionUrban ? convertToEnglishDecimal(formdata.fuleConsumptionUrban) : '');
        form.append('fuel_consumption_extra_urban_l_per_100km', formdata.fuleConsumptionExtraUrban ? convertToEnglishDecimal(formdata.fuleConsumptionExtraUrban) : '');
        form.append('co2_emissions_combined_g_per_km', formdata.co2emissioncombined ? convertToEnglishDecimal(formdata.co2emissioncombined) : '');
        form.append('vehicle_total_weight_kg', formdata.maxweight !== null && formdata.maxweight !== '' && formdata.maxweight !== "0" ? formatNumberFromLocale(formdata.maxweight) : '');
        form.append('air_conditioning', formdata.airConditioning !== 'undefined' && formdata.airConditioning !== null ? formdata.airConditioning : '')
        form.append('parking_aid', (formdata.parkingaid !== 'undefined' && formdata.parkingaid !== null) ? formdata.parkingaid : '')
        form.append('original_color', formdata.OriginalColor !== null && formdata.OriginalColor !== 'undefined' ? formdata.OriginalColor : '')
        form.append('color', formdata.color !== 'undefined' && formdata.color !== null ? formdata.color : '')
        form.append('length_cm', formdata.length !== null && formdata.length !== '' && formdata.length !== "0" ? formatNumberFromLocale(formdata.length) : '')
        form.append('width_cm', formdata.width !== null && formdata.width !== '' && formdata.width !== "0" ? formatNumberFromLocale(formdata.width) : '')
        form.append('height_cm', formdata.height !== null && formdata.height !== '' && formdata.height !== "0" ? formatNumberFromLocale(formdata.height) : '')
        form.append('no_of_seats', formdata.noofsetas !== null ? formdata.noofsetas : '')
        form.append('equipments', JSON.stringify(formdata.equipments))

        const formDataObject = {};
        form.forEach((value, key) => {
            formDataObject[key] = value;
        });

        console.log('formDataObject', formDataObject);
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
        await axios.put(url, form, { headers: { 'Authorization': token } })
            .then((response) => {
                setLoading(false)
                console.log('response', response);
                if (response?.data.status === 1) {
                    callAddDetailApi(addDetails.vehicle_ad_overview_data.id)
                    if (btnAction === 'publish') {
                        let status = addStatus === 'inprogress' ? 'publish' : addStatus === 'published' ? 'unpublish' : addStatus === 'unpublished' ? 'republish' : ''
                        callAddPublishApi(status)
                    } else if (btnAction == 'next') {
                        const componentPage = `/ad/${addDetails.vehicle_ad_overview_data.id}`;
                        navigate(componentPage)
                    } else if (btnAction == 'preview') {
                        openNewTab()
                    } else if (btnAction == 'back') {
                        onBackPress()
                    } else if (btnAction == 'tabchange') {
                        onClick()
                    }
                } else {
                    console.log('response', response);
                    setShowAlert(true)
                    setError(response.data.message)
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log('error', error);
                if (error.response) {
                    setShowAlert(true)
                    setError(error.response.data.message)
                }
            });
    }


    const callAddPublishApi = async (status) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.ADD_PUBLISH}${addDetails.vehicle_ad_overview_data.id}/?publish_status=${status}`;
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";
        await axios
            .get(url, { headers: { Authorization: token } })
            .then((response) => {
                console.log("response", response);
                setLoading(false);
                if (response?.data.status === 1) {
                    callAddDetailApi(addDetails.vehicle_ad_overview_data.id)
                } else {
                    console.log("response", response);
                    setShowAlert(true)
                    setError(response.data.message)
                }
            })
            .catch(function (error) {
                setLoading(false);
                if (error.response) {
                    setShowAlert(true)
                    setError(error.response.data.message)
                }
            });
    }

    return (
        <>
            <div style={{ paddingBottom: 20 }}>
                {loading && (
                    <div className="loader">
                        <ClipLoader
                            color='orange'
                            loading={loading}
                            size={50}
                        />
                    </div>
                )}
                <Row>
                    <Col md={5} xxl={3} xl={4} lg={4} sm={7}>
                        <DropDownInput
                            type={'text'}
                            isOpen={openDropdown === 'Typ'}
                            onToggle={() => handleDropdownToggle('Typ')}
                            label="Typ*"
                            value={formData.type}
                            dropDownData={typeData}
                            singleselection={true}
                            onChange={e => handleInputChange('type', e.target.value)}
                            onSelect={(item) => handleInputChange('type', item)} />
                        {formdataError.type && (
                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: 20,
                                    marginTop: -10,
                                }}
                            >
                                <img src={require('../../assets/alert.png')} style={{ height: 20, width: 20 }}></img>
                                <div className="error-message">
                                    {formdataError.type}
                                </div>
                            </div>
                        )}
                        <DropDownInput
                            type={'text'}
                            label="Hersteller*"
                            value={formData.manfacturer}
                            isOpen={openDropdown === 'Hersteller'}
                            onToggle={() => handleDropdownToggle('Hersteller')}
                            singleselection={true} dropDownData={manfacturerData} onChange={e => handleInputChange('manfacturer', e.target.value)} onSelect={(item) => handleInputChange('manfacturer', item)} />

                        {formdataError.manfacturer && (
                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: 20,
                                    marginTop: -10,
                                }}
                            >
                                <img src={require('../../assets/alert.png')} style={{ height: 20, width: 20 }}></img>
                                <div className="error-message">
                                    {formdataError.manfacturer}
                                </div>
                            </div>
                        )}
                        <DropDownInput
                            type={'text'}
                            label="Modell"
                            value={formData.model}
                            isOpen={openDropdown === 'Modell'}
                            onToggle={() => handleDropdownToggle('Modell')}
                            dropDownData={modelData} singleselection={true} onChange={e => handleInputChange('model', e.target.value)} onSelect={(item) => handleInputChange('model', item)} />
                        <DropDownInput
                            type={'text'}
                            label="Verfügbarkeit"
                            isOpen={openDropdown === 'Verfügbarkeit'}
                            onToggle={() => handleDropdownToggle('Verfügbarkeit')}
                            value={formData.availability} singleselection={true} dropDownData={vehicleAvailabilityData} onChange={e => handleInputChange('availability', e.target.value)} onSelect={(item) => handleInputChange('availability', item)} />
                        <MeasurementInput type={'text'} inputtype={"integer"} minimumNumber={1} label="Kilometerstand*" value={formData.mileage} measurement={'Km'} onChange={e => handleInputChange('mileage', e.target.value)} />
                        {formdataError.mileage && (
                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: 20,
                                    marginTop: -10,
                                }}
                            >
                                <img src={require('../../assets/alert.png')} style={{ height: 20, width: 20 }}></img>
                                <div className="error-message">
                                    {formdataError.mileage}
                                </div>
                            </div>
                        )}
                        <TopLabelInput type={'text'} label="Fahrzeugnummer" value={formData.vehicleNumber} onChange={e => handleInputChange('vehicleNumber', e.target.value)} />
                        <TopLabelInput type={'number'} inputtype={'year'} label="Baujahr*" value={formData.yearOfManfacture} onChange={e => handleInputChange('yearOfManfacture', e.target.value)} />
                        {formdataError.yearOfManfacture && (
                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: 20,
                                    marginTop: -10,
                                }}
                            >
                                <img src={require('../../assets/alert.png')} style={{ height: 20, width: 20 }}></img>
                                <div className="error-message">
                                    {formdataError.yearOfManfacture}
                                </div>
                            </div>
                        )}
                        <DropDownInput
                            type={'text'}
                            label="Kraftstoffart"
                            isOpen={openDropdown === 'Kraftstoffart'}
                            onToggle={() => handleDropdownToggle('Kraftstoffart')}
                            value={formData.fuelType} dropDownData={fuelTypeData} singleselection={true} onChange={e => handleInputChange('fuelType', e.target.value)} onSelect={(item) => handleInputChange('fuelType', item)} />
                        <DropDownInput
                            type={'text'}
                            label="Getriebe"
                            isOpen={openDropdown === 'Getriebe'}
                            onToggle={() => handleDropdownToggle('Getriebe')}
                            value={formData.gearBoxType} dropDownData={gearBoxType} singleselection={true} onChange={e => handleInputChange('gearBoxType', e.target.value)} onSelect={(item) => handleInputChange('gearBoxType', item)} />
                        <TopLabelInput type={'number'} inputtype={'seatsize'} minimumNumber={1} label={'Anzahl der Sitze*'} value={formData.noofsetas} onChange={e => handleInputChange('noofsetas', e.target.value)} />
                        {formdataError.noofsetas && (
                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: 20,
                                    marginTop: -10,
                                }}
                            >
                                <img src={require('../../assets/alert.png')} style={{ height: 20, width: 20 }}></img>
                                <div className="error-message">
                                    {formdataError.noofsetas}
                                </div>
                            </div>
                        )}
                    </Col>
                    <Col md={5} xxl={3} xl={4} lg={4} sm={7}>
                        <MeasurementInput type={'number'} minimumNumber={0} label="Leistung" value={formData.performance} measurement={'PS'} onChange={e => handleInputChange('performance', e.target.value)} />
                        <DropDownInput
                            type={'text'}
                            label="Schadstoffklasse"
                            isOpen={openDropdown === 'Schadstoffklasse'}
                            onToggle={() => handleDropdownToggle('Schadstoffklasse')}
                            value={formData.emissionClass} singleselection={true} dropDownData={emissionClass} onChange={e => handleInputChange('emissionClass', e.target.value)} onSelect={(item) => handleInputChange('emissionClass', item)} />
                        <DropDownInput
                            type={'text'}
                            label="Umweltplakette"
                            isOpen={openDropdown === 'Umweltplakette'}
                            onToggle={() => handleDropdownToggle('Umweltplakette')}
                            value={formData.environmentalBadge} singleselection={true} dropDownData={environmentalBadge} onChange={e => handleInputChange('environmentalBadge', e.target.value)} onSelect={(item) => handleInputChange('environmentalBadge', item)} />
                        <div className="checkbox-container">
                            <Form.Check
                                type="checkbox"
                                className='form-check'
                                // label={'Partikelfilter'}
                                checked={formData.particleFilter === 'Ja' ? true : false}
                                onChange={() => handleCheckboxChange('filter')}
                            />
                            <span className='custom-form-check-label'>Partikelfilter</span>
                        </div>
                        <div className="checkbox-container">
                            <Form.Check
                                type="checkbox"
                                className='form-check'
                                // label={'Partikelfilter'}
                                checked={formData.startstop === 'Ja' ? true : false}
                                onChange={() => handleCheckboxChange('start-stop')}
                            />
                            <span className='custom-form-check-label'>Start/Stopp-Automatik</span>
                        </div>
                        <MeasurementInput type={'text'} inputtype={"decimal"} minimumNumber={0} label="Kraftstoffverbrauch (komb.)" value={formData.fuleConsumptionCombined} measurement={'l/100km'} onChange={e => handleInputChange('fuleConsumptionCombined', e.target.value)} />
                        <MeasurementInput type={'text'} inputtype={"decimal"} minimumNumber={0} label="Kraftstoffverbrauch (innerorts)" value={formData.fuleConsumptionUrban} measurement={'l/100km'} onChange={e => handleInputChange('fuleConsumptionUrban', e.target.value)} />
                        <MeasurementInput type={'text'} inputtype={"decimal"} minimumNumber={0} label="Kraftstoffverbrauch (außerorts)" value={formData.fuleConsumptionExtraUrban} measurement={'l/100km'} onChange={e => handleInputChange('fuleConsumptionExtraUrban', e.target.value)} />
                        <MeasurementInput type={'text'} inputtype={"decimal"} minimumNumber={0} label="CO,-Emissionen (komb.)" value={formData.co2emissioncombined} measurement={'g/km'} onChange={e => handleInputChange('co2emissioncombined', e.target.value)} />
                        <MeasurementInput type={'text'} inputtype={"integer"} minimumNumber={0} label="Max. Gesamtgewicht" value={formData.maxweight} measurement={'Kg'} onChange={e => handleInputChange('maxweight', e.target.value)} />
                    </Col>
                    <Col md={5} xxl={3} xl={4} lg={4} sm={7}>
                        <DropDownInput
                            type={'text'}
                            label="Klimatisierung"
                            isOpen={openDropdown === 'Klimatisierung'}
                            onToggle={() => handleDropdownToggle('Klimatisierung')}
                            value={formData.airConditioning} singleselection={true} dropDownData={airConditioning} onChange={e => handleInputChange('airConditioning', e.target.value)} onSelect={(item) => handleInputChange('airConditioning', item)} />
                        <DropDownInput
                            type={'text'}
                            label="Einparkhilfe"
                            isOpen={openDropdown === 'Einparkhilfe'}
                            onToggle={() => handleDropdownToggle('Einparkhilfe')}
                            value={formData.parkingaid} singleselection={true} dropDownData={parkingAid} onChange={e => handleInputChange('parkingaid', e.target.value)} onSelect={(item) => handleInputChange('parkingaid', item)} />
                        <DropDownInput
                            type={'text'}
                            label="Farbe Herstellerbezeichnung"
                            isOpen={openDropdown === 'Farbe Herstellerbezeichnung'}
                            onToggle={() => handleDropdownToggle('Farbe Herstellerbezeichnung')}
                            value={formData.OriginalColor} singleselection={true} dropDownData={originalColor} onChange={e => handleInputChange('OriginalColor', e.target.value)} onSelect={(item) => handleInputChange('OriginalColor', item)} />
                        <DropDownInput
                            type={'text'}
                            label="Farbe"
                            isOpen={openDropdown === 'Farbe'}
                            onToggle={() => handleDropdownToggle('Farbe')}
                            value={formData.color} singleselection={true} dropDownData={color} onChange={e => handleInputChange('color', e.target.value)} onSelect={(item) => handleInputChange('color', item)} />
                        <MeasurementInput type={'text'} inputtype={"integer"} minimumNumber={0} label="Lange" value={formData.length} measurement={'cm'} onChange={e => handleInputChange('length', e.target.value)} />
                        <MeasurementInput type={'text'} inputtype={"integer"} minimumNumber={0} label="Breite" value={formData.width} measurement={'cm'} onChange={e => handleInputChange('width', e.target.value)} />
                        <MeasurementInput type={'text'} inputtype={"integer"} minimumNumber={0} label="Höhe" value={formData.height} measurement={'cm'} onChange={e => handleInputChange('height', e.target.value)} />
                    </Col>
                    <Col md={5} xl={3} lg={4} sm={6}>
                        <div className='heilightdiv'>
                            <span className='hilight_floatlabel'>Ausstattung</span>
                            {/* <div>
                                <input className='inputheilight' placeholder='Ausstattung 1' value={formData.Ausstattung1} onInput={e => handleInputChange('Ausstattung1', e.target.value)}></input>
                                <div className='inputborder' />
                            </div>
                            <div>
                                <input className='inputheilight' placeholder='Ausstattung 2' value={formData.Ausstattung2} onInput={e => handleInputChange('Ausstattung2', e.target.value)}></input>
                                <div className='inputborder' />
                            </div>
                            <div>
                                <input className='inputheilight' placeholder='Ausstattung 3' value={formData.Ausstattung3} onInput={e => handleInputChange('Ausstattung3', e.target.value)}></input>
                                <div className='inputborder' />
                            </div>
                            <div>
                                <input className='inputheilight' placeholder='Ausstattung 4' value={formData.Ausstattung4} onInput={e => handleInputChange('Ausstattung4', e.target.value)}></input>
                                <div className='inputborder' />
                            </div>
                            <div>
                                <input className='inputheilight' placeholder='Ausstattung 5' value={formData.Ausstattung5} onInput={e => handleInputChange('Ausstattung5', e.target.value)}></input>
                                <div className='inputborder' />
                            </div> */}
                            {[...Array(euipemnttInputCount)].map((_, index) => (
                                <div>
                                    <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                                        <input
                                            className='inputheilight'
                                            style={{ flex: 1 }}
                                            placeholder={`Ausstattung ${index + 1}`}
                                            value={formData.equipments[index]}
                                            onChange={e => handleEquipmentInputChange(index, e.target.value)}
                                        // onChange={e =>  handleInputChange(`equipment${index + 1}`, e.target.value)}
                                        />
                                        {index >= 5 && (
                                            <button className="delete-btn" onClick={() => deleteEquipment(index)}><img src={require('../../assets/close.png')} style={{ width: 15, height: 15 }} /></button>
                                        )}

                                    </div>
                                    <div className='inputborder' />
                                </div>
                            ))}
                            <button className="add_equipment_btn" onClick={() => addEquipment()}>Ausstattung hinzufügen+</button>
                        </div>
                    </Col>
                    <Col md={3}></Col>
                </Row>
                <Row style={{ marginTop: 20, alignItems: 'center', display: 'inline-flex' }}>
                    <Col>
                        <button className="add_back_btn" style={{ marginTop: 5 }} onClick={handleBackClick}>{Strings.back}</button>
                    </Col>
                    <Col>
                        <button className="next-btn" style={{ marginTop: 5, minWidth: '120px' }} onClick={() => { handleNextClick('next') }}>{Strings.next}</button>
                    </Col>
                    <Col>
                        <button className="add_preview_btn" style={{ marginTop: 5 }} onClick={() => { handleNextClick('preview') }} >{Strings.preview}<img src={require('../../assets/desktop.png')} style={{ width: 15, height: 15, marginLeft: 5 }} /></button>
                    </Col>
                    <Col>
                        {addStatus !== '' && <button style={{ marginTop: 5, minWidth: '120px' }} className={(addStatus == 'inprogress' || addStatus == 'unpublished') ? "publishbtn" : 'un_publish_btn'} onClick={() => handlePublishClick()}> <span>{addStatus == 'inprogress' ? 'Veröffentlichen' : addStatus == 'published' ? 'Pausieren' : addStatus == 'unpublished' ? 'Erneut veröffentlichen' : ''}</span></button>}
                    </Col>
                </Row>
            </div>
            {showalert &&
                <AlertComponent message={error} onClose={() => { setShowAlert(false) }}></AlertComponent>
            }
        </>
    )
}

export default AdBasicDetails