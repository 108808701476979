import React, { useState, useEffect } from "react";
import '../AdPreviewPage/AdPreviewPage.css';

const DiagonalLine = () => {
    const [size, setSize] = useState(Math.min(window.innerWidth, window.innerHeight) * 0.5);

    // useEffect(() => {
    //     const handleResize = () => {
    //         setSize(Math.min(window.innerWidth, window.innerHeight) * 0.6);
    //     };

    //     window.addEventListener("resize", handleResize);
    //     return () => window.removeEventListener("resize", handleResize);
    // }, []);

    const strokeWidth = 50; // Fixed stroke width
    const fontSize = size * 0.1; // Adjust text size dynamically

    // Dynamic shift factor based on screen size
    const shiftFactor = size * 0.2; // Adjusts how much the line shifts

    // Adjusted positions for dynamic shift behavior
    const x1 = size / 2 - shiftFactor; // Moves left when shrinking
    const y1 = -strokeWidth / 2; // Ensures it starts at the top
    const x2 = size + strokeWidth / 2; // Extends beyond right edge
    const y2 = size / 2 + shiftFactor; // Moves down when shrinking

    return (
        <svg
            width={size}
            height={size}
            style={{
                position: "absolute",
                top: 0,
                right: 0,
                background: "transparent", // No background
            }}
        >
            {/* Diagonal Line */}
            <line
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                stroke="red"
                strokeWidth={strokeWidth}
                strokeLinecap="butt"
            />

            {/* "Sold" Text inside the line */}
            <text
                className="soldtext"
                x={(x1 + x2) / 2}
                y={(y1 + y2) / 2}
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize={fontSize}
                fontWeight="bold"
                fill="white"
                transform={`rotate(${Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI)}, ${(x1 + x2) / 2}, ${(y1 + y2) / 2})`}
            >
                Verkauft
            </text>
        </svg>
    );
};

export default DiagonalLine;
