import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';
import { DataContext } from "./DataContext";


const LocalURL = 'test-2bezx2tv7q-ey.a.run.app'
const LIVEURL = 'cvs-prod-2bezx2tv7q-ey.a.run.app'

export const deviceLocale = navigator.language || 'en-US'; // Default to 'en-US' if no locale is fo
// export const deviceLocale = 'de-DE'; // Default to 'en-US' if no locale is fo
export const previewsitemapurl = 'https://test-2bezx2tv7q-ey.a.run.app/sitemap.xml'
export const prodsitemapurl = 'https://cvs-prod-2bezx2tv7q-ey.a.run.app/sitemap.xml'

export const impressumLink = 'https://app2.campervanspot.com/impressum'
export const dataprotection = 'https://app2.campervanspot.com/datenschutzerklaerung'
export const termsandconditions = 'https://app2.campervanspot.com/allgemeine-geschäftsbedingungen'

export const instaUserId = '8279472675480772'
export const instaAccessToken = 'IGQWRQRGgyMWtuZA08tTkUydDluYWJyVndhTGlBWl93QVd2RU40ZAlZAhcENLakdxa3VMN2tJaTVqMkJRc1JXZAlVHZAG5pQlZAvazVyRlRrVEQtOGdHVnl0R0RjWmRsb3MzVmNWalB4TGRsdWFpZAzY4dmNfLTZANekJKUGMZD'




export const URLS = {
  APP_LOCAL_URL1: 'http://localhost:3000',
  APP_STAGIN_URL1: 'https://campervanspot--cvs-test-preview-3ki4ylap.web.app',
  APP_LIVE_URL1: 'https://app.campervanspot.com',
  APP_LOCAL_URL: 'http://localhost:3000/',
  APP_STAGIN_URL: 'https://campervanspot--cvs-test-preview-3ki4ylap.web.app/',
  APP_LIVE_URL: 'https://app.campervanspot.com/',
  BASE_URL: `https://${LIVEURL}/api/v1/`,
  BASE_URL1: `https://${LIVEURL}/api/`,
  LOGIN_URL: 'login/',
  REGISTRATION_URL: 'register/',
  LOGOUT: 'logout/',
  ALL_COMPANIES: 'companyinformations/',
  FILTERD_COMPANIES: 'companyinformations/?type_of_business=',
  COMPANY_DETAILS: 'companyinformation/',
  UPDATE_COMPANY_INFO: 'companyinformationnew/',
  CREATE_BUSINESS_PAGE: 'companybusinesspage/',
  VEHILCE_ADD_CREATE: 'vehicleadoverviewcreate/',
  VEHILCE_ADD_OVERVIEW_UPDATE: 'vehicleadoverviewupdate/',
  VEHICLE_ADD_GALLERY_CREATE: 'vehicleadgallerycreate/',
  VEHICLE_ADD_GALLERY_DATA_CREATE: 'vehicleadgallerycategorydescription/',
  VEHICLE_ADD_GALLERY_DATA_UPDATE: 'vehicleadgallerycategorydescriptionupdate/',
  VEHICLE_ADD_GALLERY_UPDATE: 'vehicleadgalleryupdate/',
  VEHICLE_ADD_GALLERY_DELETE: 'vehicleadgallerydelete/',
  ADD_TECHNICALDATA_CREATE: 'vehicleadfeaturetechnicaldatacreate/',
  ADD_TECHNICALDATA_UPDATE: 'vehicleadfeaturetechnicaldataupdate/',
  CREATE_TECHNICLE_DATA: 'vehicletechnicaldatacreate/',
  UPDATE_TECHNICLE_DATA: 'vehicletechnicaldataupdate/',
  FULL_ADD_DETAILS: 'vehicleaddata/',
  VERIFY_EMAIL: 'useraccountverification/?uuid=',
  FORGOT_PASSWORD: 'useremailverification/',
  CHANGE_PASSWORD: 'changepassword/',
  BUSINESSPAGE_APPROVAL: 'companybusinesspageapprovalemail/',
  ADDS_LIST: 'vehicleadoverviewlist/',
  MODELS_LIST: 'vehiclemodels/',
  BASIC_DETAILS_LIST: 'vehicletechnicaldatalist/',
  ADD_PUBLISH: 'vehicleadpublish/',
  SEARCH_ADDS: `vehicleads/`,
  CHAT_MESSAGE_CREATE: 'chatmessagecreate/',
  PRIVATE_USER_PROFILE: 'privateuserinformationdetail/',
  UPDATE_USER_PROFILE: 'privateuserinformationupdate/',
  CHAT_DETAILS: 'chatmessageslist/',
  UPDATE_MESSAGE: 'chatmessageupdate/',
  CHAT_LIST: 'chatlist/',
  UNREAD_MESSAGE_COUNT: 'unreadcount/',
  DELETE_MESSAGE: 'chatmessagedelete/',
  ADD_ANALYTICS: 'adsanalyticsdata/',
  DASHBOARD_CHAT_LIST: 'dashboardchatlist/',
  BUSINESSPAGE_ANALYTICS: 'businesspagesanalyticsdata/',
  LOGIN_AD: 'userloginredirect/',
  AD_GALLERY_ORDER_UPDATE: 'vehicleadgalleryorderupdate/',
  GET_INSTA_TOKEN: 'instaaccesstoken/',
  USER_ADS_LIST: 'vehicleadslist/?formatted_company_name=',
  INSTA_POSTS: 'instagramposts/',
  INSTA_POST_UPDATE: 'instagrampostupdate/',
  INSTA_PUBLISH: 'instapostpublish/',
  DOMAIN_EXIST: 'companynamecheck/?',
  AD_BUSINESS_STATE_UPDATE: 'adbusinessstateupdate/'
}

export const newtaburl = process.env.NODE_ENV === "development" ? URLS.APP_LOCAL_URL : URLS.APP_LIVE_URL;

export const Strings = {
  email: 'E-Mail',
  password: 'Kennwort',
  forgotPassword: 'Kennwort vergessen',
  confirm_password: 'Kennwort bestätigen',
  login: 'Anmelden',
  changePassword: 'Passwort ändern',
  close: 'Schließen',
  cancel: 'Abbrechen',
  all_btn: ' Alle sehen',
  email_err: 'Gib bitte eine email-Adresse an.',
  invalid_email: 'Gib bitte eine gültige email-Adresse an.',
  password_err: 'Gib bitte ein Passwort an.',
  confirm_password_err: 'Bitte bestätige das Passwort.',
  confirm_password_error2: 'Passwort und Bestätigungspasswort müssen gleich sein.',
  business_page_notfound: 'Geschäftsseite nicht gefunden',
  change_password_text: 'Bitte vergib ein neues Passwort für Deinen Account:',
  page_published: 'Deine Unternehmensseite ist veröffentlicht.',
  page_inprogress: 'Du hast Deine Seite noch nicht publiziert und nur Du kannst sie aktuell sehen.',
  page_waiting: 'Wir prüfen gerade Ihre Unternehmensseite und werde diese kurzfristig frei schalten.',
  registration: 'Registrieren',
  registration_desc1: 'Sichern Sie sich jetzt ',
  registration_desc2: ' kostenlos',
  registration_desc3: ' Ihre Unternehmensseite auf',
  registration_desc4: ' campervanspot.com',
  company_name: 'Unternehmensname',
  domain_name: 'Domain-Name des Unternehmens',
  first_name: 'Vorname',
  last_name: 'Nachname',
  role: 'Rolle im Unternehmen',
  policy_description: `Campervanspot.com verpflichtet sich, Ihre Privatsphäre zu schützen und zu respektieren. Wir nutzen Ihre persönlichen Daten nur zur Verwaltung Ihres Kontos und zur Bereitstellung der von 
    Ihnen angeforderten Produkte und Dienstleistungen. Von Zeit zu Zeit möchten wir Sie über 
    unsere Produkte und Dienstleistungen sowie andere Inhalte, die für Sie von Interesse sein könnten, informieren. 
    Wenn Sie damit einverstanden sind, dass wir Sie zu diesem Zweck kontaktieren, geben Sie bitte unten an, 
    wie Sie von uns kontaktiert werden möchten:`,
  terms2: `Sie können diese Benachrichtigungen jederzeit abbestellen. Weitere Informationen zum Abbestellen, zu unseren Datenschutzverfahren und dazu, wie wir Ihre Privatsphäre schützen und respektieren, finden Sie in unserer Datenschutzrichtlinie.`,
  terms1: ` Um Ihnen die gewünschten Inhalte bereitzustellen, 
    müssen wir Ihre persönlichen Daten speichern und verarbeiten. Wenn Sie damit einverstanden sind, dass wir Ihre personenbezogenen Daten für diesen Zweck speichern, aktivieren Sie bitte das folgende Kontrollkästchen.`,
  checkbox1: 'Ich stimme zu, andere Benachrichtigungen von Campervanspot.com zu erhalten.',
  checkbox2: 'Ich stimme der Speicherung und Verarbeitung meiner persönlichen Daten durch Campervanspot.com zu.*',
  send: 'Absenden',
  why: 'Warum?',
  app_description: 'Wir bauen gerade die Vermarktungsplattform für hochwertige Campervans und Wohnmobile für Ausbauer, Händler und Hersteller.',
  website: 'Campervanspot.com GmbH i.G.',
  company_page_guide: 'Mit Ihrer Unternehmensseite profitieren Sie kostenlos von:',
  company_page_guide1: 'Attraktive und konsumentengerechte Präsentation Ihres Unternehmens.',
  company_page_guide2: 'Wir bewerben die bei uns gelisteten Unternehmen regelmäßig auf Social-Media.',
  company_page_guide3: 'Grundlage zur Vermarktung Ihrer Fahrzeuge auf unserer Plattform.',
  finisherpage_desc1: 'Hier finden sie',
  finisherpage_desc2: 'Den richtigen Camper Van',
  finisher_haeding1: 'Alle ',
  finisher_haeding2: ' Unternehmen  ',
  address: 'Adresse',
  create_add: 'Anzeige erstellen',
  vehicle_name: 'Fahrzeug Name*',
  conversion_brand: 'Ausbauer Marke*',
  price: 'Preis*',
  noofseats: 'Anzahl der Sitze*',
  currency: 'Währung*',
  heilights: 'Highlights',
  back: 'Zurück',
  next: 'Weiter',
  preview: 'Preview',
  gallery: 'Galerie',
  home_prelaunch_text: `Erste Einblicke! Entdecken Sie ein sorgfältig ausgewähltes Verzeichnis von führenden Unternehmen, 
    die sich auf Camper-Van-Umwandlungen spezialisieren. Erhalten Sie vor dem offiziellen Start Zugang zu einer Vielzahl von Experten, die Ihnen dabei helfen können, Ihr Traum-Wohnmobil zu gestalten!`,
  silent_launch_text: `Beim silent launch haben eingeladene Nutzer exklusiven Zugang.
    Diese Phase ermöglicht es einer ausgewählten Gruppe, die Plattform zu erkunden und zu nutzen, noch bevor sie öffentlich verfügbar ist.`,
  publiclaunch_text: ` Die große Enthüllung! Jetzt ist campervanspot.com für alle zugänglich. Erleben Sie die Vielfalt der besten Camper-Vans auf einer Plattform, die für jeden offen ist.`,
  required_error: 'Bitte füll dieses Pflichtfeld aus.',
  company_name_required: 'Firmenname ist erforderlich.',
  domain_required: 'Domänenname ist erforderlich.',
  first_name_required: 'Vorname ist erforderlich.',
  last_name_required: 'Nachname ist erforderlich.',
  role_required: 'Deine Rolle im Unternehmen ist erforderlich',
  image_size_error: 'Die Bildgröße ist größer als 4,5 MB. Bitte wähle ein kleineres Bild.',
  image_size_big_error: 'Die Bildgröße ist größer als 15 MB. Bitte wähle ein kleineres Bild.',
  image_filename_error: 'Der Dateiname ist zu lang. Bitte verwende einen kürzeren Dateinamen.',
  '404_error_text': '404: Es tut uns sehr leid, aber wir konnte die von Dir gesuchte Seite nicht finden. Bitte starte nochmal von hier: https://campervanspot.com',

}

export const modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] }
    ],
    [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
  ]
};

export const formats = [
  "header", "height", "bold", "italic",
  "underline", "strike", "blockquote",
  "list", "color", "bullet", "indent",
  "link", "image", "align", "size",
];

export const getCookieValue = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};


export const formatMilageNumber = (value) => {
  return new Intl.NumberFormat('de-DE').format(value);
}

export const formatCurrency = (value, currency) => {
  console.log('currency', currency)
  if (value !== '' && currency !== '') {
    let locale = 'de-DE'; // German locale for both EUR and CHF
    let formattedValue = new Intl.NumberFormat(locale, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
    return `${formattedValue} ${currency.toUpperCase()}`;
  } else {
    return ''
  }

};

export const formatNumberToLocale = (number) => {
  const formatter = new Intl.NumberFormat(deviceLocale);
  return formatter.format(number);
};

export const formatNumberFromLocale = (price) => {
  const formatter = new Intl.NumberFormat(deviceLocale);
  const parts = formatter.formatToParts(123456.789); // Example number
  const groupSeparator = parts.find((part) => part.type === "group").value; // e.g., ',' or '.'
  const decimalSeparator = parts.find((part) => part.type === "decimal").value; // e.g., '.' or ','

  // Remove group separators and replace decimal separator with '.'
  const normalizedNumber = price
    .split(groupSeparator).join('') // Remove group separators
    .split(decimalSeparator).join('.'); // Replace decimal separator with '.'

  return parseFloat(normalizedNumber); // Convert to a standard number
};

// Custom hook to track page views
export const usePageTracking = (pageTitle) => {
  const location = useLocation();
  const {
    consentGranted
  } = useContext(DataContext);

  React.useEffect(() => {
    if (consentGranted) {
      const pagePath = location.pathname + location.search;
      // Send pageview event
      console.log('Sending pageview event to Google Analytics:', pagePath, pageTitle);
      ReactGA.send({ hitType: 'pageview', page: pagePath, title: pageTitle });
    }
  }, [location]);
}

//  const debouncedTrackPageTitleChange = debounce((title) => {
//         if (previousTitleRef.current !== title && window.trackPageTitleChange) {
//             window.trackPageTitleChange(title);
//         }
//         // Update the previous title reference
//         previousTitleRef.current = title;
//     }, 500);
