import React, { useState, useEffect, useContext, useRef } from 'react';
import '../../app.css';
import '../DashBoard/DashBoard.css'
import Header from '../../Components/Header/Header';
import { useNavigate, useLocation } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { deviceLocale, newtaburl, URLS, usePageTracking } from '../../utilities/constants';
import axios from 'axios';
import { DataContext } from '../../utilities/DataContext';
import { ClipLoader } from "react-spinners";
import { debounce } from 'lodash';
import ReactGA from 'react-ga4';
import SuccessAlert from "../../Components/SuccessAlert";
import { useAnalytics } from '../../utilities/AnalyticsContext';

const DashBoard = () => {
    const { markTitleUpdated } = useAnalytics()
    const navigate = useNavigate();
    const location = useLocation();
    const [addsList, setAddsList] = useState([])
    const [activeAdds, setActiveAdds] = useState([])
    const [InprogressAdds, setInprogressAdds] = useState([])
    const [activeAdsCount, setActiveAdsCount] = useState(0)
    const { setPageTitleAvailable, token, addDetails, setAddDetails, userType, setUserType, setToken, setIsLoggedIn, setUserData, setCompanyInfo, setMessageCount, setChatData, setTab, setNoOfBedErr, setBusinessForm2data, setBusinessForm1data } = useContext(DataContext);
    const [loading, setLoading] = useState(false);
    const [adAnalyticsData, setAnalyticsData] = useState(null);
    const [profileAnalyticsData, setProfileAnalyticsData] = useState(null);
    const [messages, setMessages] = useState([]);
    const [expandedContactId, setExpandedContactId] = useState(null)
    const [adsmessage, setAdsMessage] = useState('')
    const [showalert, setShowAlert] = useState(false)
    const previousTitleRef = useRef();
    const pageTitle = 'Dashboard'

    useEffect(() => {
        document.title = 'Dashboard'
        // markTitleUpdated(pageTitle)
    }, [])


    const sendAnalyticsEvent = debounce((title) => {
        if (previousTitleRef.current !== title) {
            console.log('Sending pageview event to Google Analytics:', title);
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title });
            setPageTitleAvailable(true)
            previousTitleRef.current = title;
        }
    }, 500);


    useEffect(() => {
        clearData()
        const searchParams = new URLSearchParams(location.search);
        const authtoken = searchParams.get('token');
        const businessStateKey = searchParams.get('business_state_key');
        const adIds = searchParams.get('ad_ids');

        let finalToken = ''
        if (authtoken) {
            finalToken = authtoken
            setToken(authtoken)
        } else {
            finalToken = token
        }
        //test code 
        // if (businessStateKey && adIds) {
        //     callAdsBusinessStateUpdate(adIds, businessStateKey, finalToken)
        // } else {
        //     callAddsListApi(finalToken)
        //     callAddAnalyticsApi(finalToken)
        //     callDashBoardChatList(finalToken)
        //     if (userType !== 'private-user') {
        //         callBusinessAnalyticsApi(finalToken)
        //     }
        // }

        callAddsListApi(finalToken)
        callAddAnalyticsApi(finalToken)
        callDashBoardChatList(finalToken)
        if (userType !== 'private-user') {
            callBusinessAnalyticsApi(finalToken)
        }
    }, []);

    const debouncedTrackPageTitleChange = debounce((title) => {
        if (previousTitleRef.current !== title && window.trackPageTitleChange) {
            window.trackPageTitleChange(title);
        }
        // Update the previous title reference
        previousTitleRef.current = title;
    }, 500);


    const formatetime = (timestamp) => {
        const date = new Date(timestamp);

        // Extract hours, minutes, and seconds
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const seconds = date.getUTCSeconds();

        // Format the time as HH:MM:SS
        const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} Uhr`;
        return formattedTime
    }


    const clearData = () => {
        setAnalyticsData(null)
        setProfileAnalyticsData(null)
    }

    const formatCurrency = (value, currency) => {
        console.log('currency', currency)
        if (value !== '' && currency !== '' && value !== null && currency !== null) {
            let locale = 'de-DE'; // German locale for both EUR and CHF
            let formattedValue = new Intl.NumberFormat(deviceLocale, {
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(value);
            return `${formattedValue} ${currency.toUpperCase()}`;
        } else {
            return ''
        }

    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${day}.${month}`;
    };

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const handleExpand = (id) => {
        setExpandedContactId(preid =>
            preid === id ? null : id
        )
    }

    const openNewTab = (url) => {
        window.open(url, "_blank");
    };

    const gotoChat = () => {
        if (messages.length > 0) {
            navigate('/chat')
        }
    }

    const gotoAdsList = (type) => {
        navigate('/adslist', { state: { adType: type } })
    }

    const formatCompanyName = (name) => {
        return name
            .toLowerCase()                          // Convert to lowercase
            .replace(/[^a-z0-9]+/g, '-')            // Replace non-alphanumeric characters with a hyphen
            .replace(/^-+|-+$/g, '');               // Remove leading or trailing hyphens
    };

    const handleProfileNameClick = (profile_name) => {
        const baseUrl = newtaburl
        const formattedCompanyName = formatCompanyName(profile_name);
        const componentPage = `businesspage/${formattedCompanyName}`;
        const url = baseUrl + componentPage;
        openNewTab(url)
    }

    const handleChatClick = (contact) => {
        navigate('/chat', { state: { contact: contact } })
    }

    const handleAdNameClick = (id) => {
        const baseUrl = newtaburl
        const componentPage = `ad/${id}`;
        const url = baseUrl + componentPage;
        openNewTab(url)
    }

    const createAddBtnClick = () => {
        setAddDetails({})
        navigate('/adcreate')
    }

    const handlePublishClick = (addStatus, adId, add) => {
        if (add.status === 'incomplete' && add.vehicle_ad_feature_technical_data_status === 'incomplete' && add.vehicle_basic_technical_data_status === 'incomplete') {
            // navigate('/adcreate', { state: { id: add.id } })
        } else {
            // const status = addDetails.vehicle_ad_overview_data.publish_status
            let status = addStatus === 'inprogress' ? 'publish' : addStatus === 'published' ? 'unpublish' : addStatus === 'unpublished' ? 'republish' : ''
            callAddPublishApi(status, adId)
        }

    }

    const handleBusinessStateBtnClick = (add, status) => {
        callAdBusinessStateUpdate(add.id, status)
    }

    const callAdBusinessStateUpdate = async (adid, adstatus) => {
        console.log('tokenfor adsupdate', token)
        const url = `${URLS.BASE_URL}${URLS.AD_BUSINESS_STATE_UPDATE}${adid}/?business_state_key=${adstatus}`;
        try {
            const response = await axios.patch(
                url,
                {}, // Pass an empty object if there's no request body
                {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `${token}` // Ensure token is correctly prefixed
                    }
                }
            );

            setLoading(false);

            if (response?.data.status === 1) {
                callAddsListApi(token);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    const callAdsBusinessStateUpdate = async (adids, adstatus, finalToken) => {
        const url = `${URLS.BASE_URL}${URLS.AD_BUSINESS_STATE_UPDATE}?business_state_key=${adstatus}&ad_ids=${adids}`;
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";
        await axios.get(url, { headers: { 'Authorization': finalToken } })
            .then((response) => {
                setLoading(false)
                if (response?.data.status === 1) {
                    setShowAlert(true)
                    const data = response.data.message
                    setAdsMessage(data)
                    callAddsListApi(finalToken)
                    callAddAnalyticsApi(finalToken)
                    callDashBoardChatList(finalToken)
                    if (userType !== 'private-user') {
                        callBusinessAnalyticsApi(finalToken)
                    }
                }
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }

    const callDashBoardChatList = async (authToken) => {
        const url = `${URLS.BASE_URL}${URLS.DASHBOARD_CHAT_LIST}`;
        await axios.get(url, { headers: { 'Authorization': authToken } })
            .then((response) => {
                setLoading(false)
                if (response?.data.status === 1) {
                    const data = response.data.data
                    setMessages(data)
                } else {
                    console.log('response', response);
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log('error', error);
            });
    }

    const callAddAnalyticsApi = async (authToken) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.ADD_ANALYTICS}`;
        await axios.get(url, { headers: { 'Authorization': authToken } })
            .then((response) => {
                setLoading(false)
                if (response?.data.status === 1) {
                    const data = response.data.data
                    setAnalyticsData(data)
                } else {
                    console.log('response', response);
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log('error', error);
            });
    }

    const callBusinessAnalyticsApi = async (authToken) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.BUSINESSPAGE_ANALYTICS}`;
        await axios.get(url, { headers: { 'Authorization': authToken } })
            .then((response) => {
                setLoading(false)
                if (response?.data.status === 1) {
                    const data = response.data.data
                    setProfileAnalyticsData(data)
                } else {
                    console.log('response', response);
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log('error', error);
            });
    }


    const callAddsListApi = async (authtoken) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.ADDS_LIST}`;
        await axios.get(url, { headers: { 'Authorization': authtoken } })
            .then((response) => {
                setLoading(false)
                if (response?.data.status === 1) {
                    const data = response.data.data
                    const activeads = data.filter(add => add.publish_status === 'published');
                    const inactiveAdds = data.filter(add => add.publish_status !== 'published');
                    setActiveAdds(activeads)
                    setActiveAdsCount(activeads.length)
                    setInprogressAdds(inactiveAdds)
                    setAddsList(data)
                } else {
                    console.log('response', response);
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log('error', error);
                if ((error && error.response.data && error.response.data.message)) {
                    if (error.response.data.message === 'Token fehlt!' || error.response.data.message === 'Der Token ist abgelaufen. Bitte melden Sie sich erneut an.' || error.response.data.message === 'Ungültiges Token. Bitte melden Sie sich erneut an.' || error.response.data.message === 'Benutzer nicht gefunden.') {
                        setIsLoggedIn(false)
                        setUserData({})
                        setCompanyInfo({})
                        setUserType('')
                        setTab('')
                        setNoOfBedErr('')
                        setMessageCount('')
                        setToken('')
                        setChatData([])
                        setBusinessForm1data({})
                        setBusinessForm2data({})
                        setAddDetails({})
                    }
                }
            });
    }


    const callAddPublishApi = async (status, adId) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.ADD_PUBLISH}${adId}/?publish_status=${status}`;
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";
        await axios
            .get(url, { headers: { Authorization: token } })
            .then((response) => {
                console.log("response", response);
                setLoading(false);
                if (response?.data.status === 1) {
                    callAddsListApi(token)
                } else {
                    console.log("response", response);
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                setLoading(false);
            });
    }


    const callLoginapi = async (authToken) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.LOGIN_AD}`;
        console.log('url', url)
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";
        await axios.get(url, { headers: { 'Authorization': authToken } })
            .then((response) => {
                setLoading(false)
                if (response?.data.status === 1) {
                    callAddsListApi(authToken)
                    callAddAnalyticsApi(authToken)
                    callDashBoardChatList(authToken)
                    setUserType(response?.data.user.user_type)
                    if (response?.data.user.user_type === 'private-user') {
                        const data = response?.data.user
                        setUserData(data);
                        setCompanyInfo(response?.data.private_user_profile_information)
                        callBusinessAnalyticsApi(authToken)
                    } else {
                        const data = response?.data.user
                        setUserData(data);
                        setCompanyInfo(response?.data.company_information)
                    }
                    setToken(response?.data?.token)
                    setUserType(response?.data.user.user_type)
                    setIsLoggedIn(true)
                }
            })
            .catch(function (error) {

            });
    }

    return (
        <>
            <Header />
            {loading && (
                <div className="loader">
                    <ClipLoader
                        color='orange'
                        loading={loading}
                        size={50}
                    />
                </div>
            )}
            <div style={{ height: "100vh" }}>

                <div className='maindiv'>

                    <h1 className="headertext_main mt3 mb4">
                        Dashboard
                    </h1>
                    {userType !== 'private-user' && <div>
                        <h1 className="headertext_1 mt3 mb4">
                            Statistik zu Deiner Unternehmensseite
                        </h1>
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                            <div className='analytics_layout'>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span className='analytics_header'>Impressionen</span>
                                    <span className='analytics_number'>{profileAnalyticsData ? profileAnalyticsData.total_impression_count : 0}</span>
                                </div>
                                <div className='analytics_border' />
                            </div>

                            <div className='analytics_layout'>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span className='analytics_header'>Aktive Benutzer</span>
                                    <span className='analytics_number'>{profileAnalyticsData ? profileAnalyticsData.total_active_users_count : 0}</span>
                                </div>
                                <div className='analytics_border' />
                            </div>

                            <div className='analytics_layout'>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span className='analytics_header'>Durchschnittliche Sitzungsdauer</span>
                                    <span className='analytics_header' style={{ fontSize: 13 }}>(in Sekunden)</span>
                                    <span className='analytics_number'>{profileAnalyticsData ? Math.round(profileAnalyticsData.average_session_duration) : 0}</span>
                                </div>
                                <div className='analytics_border' />
                            </div>
                        </div>
                    </div>
                    }
                    <h1 className="headertext_1 mt3 mb4">
                        Statistik zu Deinen Anzeigen
                    </h1>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                        <div className='analytics_layout'>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='analytics_header'>Impressionen</span>
                                <span className='analytics_number'>{adAnalyticsData ? adAnalyticsData.total_impression_count : 0}</span>
                            </div>
                            <div className='analytics_border' />
                        </div>

                        <div className='analytics_layout'>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='analytics_header'>Aktive Benutzer</span>
                                <span className='analytics_number'>{adAnalyticsData ? adAnalyticsData.total_active_users_count : 0}</span>
                            </div>
                            <div className='analytics_border' />
                        </div>

                        <div className='analytics_layout'>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='analytics_header'>Durchschnittliche Sitzungsdauer</span>
                                <span className='analytics_header' style={{ fontSize: 13 }}>(in Sekunden)</span>
                                <span className='analytics_number'>{adAnalyticsData ? Math.round(adAnalyticsData.average_session_duration) : 0}</span>
                            </div>
                            <div className='analytics_border' />
                        </div>

                        <div className='analytics_layout'>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='analytics_header'>Anzeigen</span>
                                <span className='analytics_number'>{activeAdsCount}</span>
                            </div>
                            <div className='analytics_border' />
                        </div>
                    </div>

                    <Row style={{ marginTop: '2vmin' }}>
                        {messages.length > 0 &&

                            <Col md={6}>
                                <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div>
                                        <h2 className="headertext_2  mt3 mb4" style={{ marginLeft: 10 }}>
                                            Nachrichten
                                        </h2>
                                    </div>
                                    {messages.length > 0 &&
                                        <div>
                                            <button className='btn_7days' style={{ color: 'rgb(39,103,352)' }} onClick={gotoChat}>Alle ansehen</button>
                                        </div>
                                    }
                                </div>
                                <div className="contacts-list">
                                    {messages.map(contact => (
                                        <div key={contact.id} className='contact_div shadowmessage' style={{ display: 'flex', flexDirection: 'row', margin: 10, alignItems: 'center', backgroundColor: 'white' }} onClick={(e) => {
                                            e.stopPropagation();
                                            if (contact.id !== null) {
                                                handleChatClick(contact)
                                            }
                                        }}>
                                            <div style={{ alignContent: 'center', display: 'flex', marginRight: 10 }}>
                                                <span className='messagename'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (contact.company_information_id !== null) {
                                                            handleProfileNameClick(contact.profile_name)
                                                        }
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        if (contact.company_information_id) {
                                                            e.target.style.textDecoration = 'underline';
                                                            e.target.style.color = 'blue'
                                                            e.target.style.cursor = 'pointer'
                                                        }
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        if (contact.company_information_id) {
                                                            e.target.style.textDecoration = 'none';
                                                            e.target.style.color = 'rgb(32, 54, 101)'
                                                            e.target.style.cursor = 'default'
                                                        }
                                                    }}
                                                >{contact.profile_name}</span>
                                            </div>
                                            <div className={`contact-item`}>
                                                <span
                                                    className="messageadtext"

                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (contact.vehicle_ad_overview) {
                                                            handleAdNameClick(contact.vehicle_ad_overview)
                                                        }
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        e.target.style.textDecoration = 'underline';
                                                        e.target.style.color = 'blue'
                                                        e.target.style.cursor = 'pointer'
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.target.style.textDecoration = 'none';
                                                        e.target.style.color = 'rgb(64, 80, 123)'
                                                        e.target.style.cursor = 'default'
                                                    }}
                                                >
                                                    {contact.vehicle_name}
                                                </span>
                                                {expandedContactId === contact.id &&
                                                    (<div>
                                                        <div className='messagetext'>
                                                            <span dangerouslySetInnerHTML={{ __html: contact.latest_message }} />
                                                        </div>
                                                        <span className='time'>{formatetime(contact.latest_message_datetime)}</span>
                                                    </div>)
                                                }

                                            </div>
                                            <div style={{ backgroundColor: contact.is_read === true ? 'rgb(240,240,240)' : 'rgb(255,246,228)', borderRadius: 20, padding: '2px 10px 2px 10px', }}>
                                                {contact.is_read === false ?
                                                    <span className='btn_read' style={{ color: 'orange' }}>
                                                        Neu
                                                    </span> : <span className='btn_read' style={{ color: 'gray' }}>
                                                        Gelesen
                                                    </span>
                                                }
                                            </div>
                                            <span onClick={(e) => {
                                                e.stopPropagation();
                                                handleExpand(contact.id)
                                            }} style={{ cursor: 'pointer', marginLeft: '10px', padding: '20px 0px 20px 0px' }}>
                                                {expandedContactId === contact.id ? <img src={contact.is_read === false ? require('../../assets/dropdownorange.png') : require('../../assets/dropdowngray.png')} style={{ width: 16, height: 16 }} /> : <img src={contact.is_read === false ? require('../../assets/rightarrowornage.png') : require('../../assets/rightarrowgray.png')} style={{ width: 16, height: 16 }} />}
                                            </span>
                                        </div>
                                    ))}
                                </div>

                            </Col>
                        }

                        <Col md={10} lg={7} xxl={6} xl={6} xs={12} sm={12} >
                            {/* <Row>
                                <Col md={8}>
                                    <h2 className="headertext_2  mt3 mb4">
                                        Aktive Anzeigen
                                    </h2>
                                </Col>
                                <Col md={4}>
                                    <button className='btn_7days' style={{ color: 'rgb(39,103,352)' }}>Alle ansehen</button>
                                </Col>
                            </Row> */}
                            <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div>
                                    <h2 className="headertext_2  mt3 mb4" style={{ marginLeft: 10 }}>
                                        Aktive Anzeigen
                                    </h2>
                                </div>
                                {activeAdds.length > 0 &&
                                    <div>
                                        <button className='btn_7days' style={{ color: 'rgb(39,103,352)' }} onClick={() => { gotoAdsList('active') }}>Alle ansehen</button>
                                    </div>
                                }
                            </div>
                            <div>
                                {activeAdds.length == 0 ?
                                    <div>
                                        <Row>
                                            <Col md={6}>
                                                <p style={{ marginLeft: 10 }}>
                                                    Du hast noch keine Anzeige aktiv!
                                                    Erstelle deine erste Anzeige und begeistere deine Kunden.
                                                </p>
                                            </Col>
                                        </Row>

                                    </div> :
                                    <div className='adds_section_cards'>
                                        {activeAdds.slice(0, 3).map(add => (<div className='card_content' style={{ position: 'relative', overflow: 'hidden' }}>
                                            {(add.availability === 'sold' || add.availability === 'reserved') &&
                                                <div className='sold_ribbon'>{add.availability === 'sold' ? 'Verkauft' : 'Reserviert'}</div>
                                            }
                                            <Row className='row_add' id={`${add.id}`} onClick={() => {
                                                setAddDetails({})
                                                navigate('/adcreate', { state: { id: add.id } })
                                            }}>
                                                <Col md={3} xs={3} xl={2} xxl={2} lg={3} sm={3} className='col_add' style={{ borderRadius: 10, display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 10, justifyContent: 'center' }}>
                                                    <img style={{ width: '100%', objectFit: 'contain', borderRadius: 10, maxHeight: 100, borderRadius: 10 }} src={add.hero_image_2k !== null ? add.hero_image_2k : require('../../assets/add_placeholder.jpeg')} />
                                                    <button class="responsive-btn unpublish_btn" onClick={(e) => {
                                                        e.stopPropagation();
                                                        handlePublishClick(add.publish_status, add.id, add);
                                                    }}>Pausieren</button>
                                                </Col>
                                                <Col md={8} xxl={9} xl={9} lg={8} sm={8} xs={8} className='col_add' style={{ marginLeft: 5, marginRight: 5 }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>
                                                        <span className='add_name'>{add.vehicle_name}</span>
                                                        {(add.publish_date !== null && add.end_date !== null) && <span className='add_date' style={{ marginLeft: 10, marginRight: 10 }}>{`${formatDateTime(add.publish_date)}-${formatDateTime(add.end_date)}`}</span>}
                                                        {/* <span className='add_date' style={{ marginLeft: 10 }}>01.02 - 30.03</span> */}
                                                        <span className='add_name' style={{ marginRight: 10 }}>{formatCurrency(add.price, add.currency)}</span>
                                                    </div>
                                                    <div>
                                                        <Row style={{ marginBottom: 10, marginLeft: 10, marginRight: 10 }}>
                                                            <Col xxl={3} xl={5} lg={5} md={5} xs={5} className='col_add'>
                                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                    <span className='add_name'>{add.ad_analytics_data.impressions}</span>
                                                                    <span className='add_date'>Impressionen</span>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3} xl={5} md={5} lg={5} xs={5} className='col_add'>
                                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                    <span className='add_name'>{add.ad_analytics_data.active_users}</span>
                                                                    <span className='add_date'>Aktive Benutzer</span>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={5} xl={9} md={7} lg={8} xs={10} className='col_add'>
                                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                    <span className='add_name'>{Math.round(add.ad_analytics_data.average_session_duration)}</span>
                                                                    <span className='add_date'>Durchschnittliche Sitzungsdauer</span>
                                                                    <span style={{ fontSize: 13 }}>(in Sekunden)</span>
                                                                </div>
                                                            </Col>
                                                            {/* <Col md={3} xs={3} className='col_add'>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                <span className='add_name'>--</span>
                                                                <span className='add_date'>Nachrichten</span>
                                                            </div>
                                                        </Col> */}
                                                        </Row>


                                                    </div>

                                                </Col>
                                            </Row>
                                            {/* <div class="button-container">
                                                <button class="responsive-btn unpublish_btn" onClick={(e) => {
                                                    e.stopPropagation();
                                                    handlePublishClick(add.publish_status, add.id, add);
                                                }}>Pausieren</button>
                                                <button class="responsive-btn unpublish_btn" onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleBusinessStateBtnClick(add, 'end_date_extend');
                                                }}>Um 30 Tage verlängern</button>

                                                {add.availability === 'Reserviert' ? <>
                                                    <button class="responsive-btn unpublish_btn" onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleBusinessStateBtnClick(add, 'Verkauft');
                                                    }}>Verkauft</button>
                                                    <button class="responsive-btn unpublish_btn" onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleBusinessStateBtnClick(add, 'Verfügbar');
                                                    }}>Reservierung aufheben</button>
                                                </> : add.availability === 'Auf Bestellung' ? <>
                                                    <button class="responsive-btn unpublish_btn" onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleBusinessStateBtnClick(add, 'Verkauft');
                                                    }}>Schon verkauft</button>

                                                    <button class="responsive-btn unpublish_btn" onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleBusinessStateBtnClick(add, 'Reserviert');
                                                    }}>Reserviert</button>
                                                </> : add.availability === 'Verkauft' ? <>
                                                    <button class="responsive-btn unpublish_btn" onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleBusinessStateBtnClick(add, 'Verfügbar');
                                                    }}>Doch nicht verkauft</button>
                                                </> : <>
                                                    <button class="responsive-btn unpublish_btn" onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleBusinessStateBtnClick(add, 'Auf Bestellung');
                                                    }}>Auf Bestellung</button>
                                                    <button class="responsive-btn unpublish_btn" onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleBusinessStateBtnClick(add, 'Verkauft');
                                                    }}>Schon verkauft</button>

                                                    <button class="responsive-btn unpublish_btn" onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleBusinessStateBtnClick(add, 'Reserviert');
                                                    }}>Reserviert</button>
                                                </>
                                                }
                                            </div> */}

                                        </div >
                                        ))}
                                    </div >}
                                <button className="add_btn2" onClick={createAddBtnClick}>+ Anzeige erstellen</button>
                                {/* {addsList.length > 0 &&
                                    <div className='cardcontainer'>
                                        <div className='section_cards'>
                                            {addsList.map(add => (
                                                <div className='imagecard' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/placeholder.webp"})` }}>
                                                    <div className='content'>
                                                        <div className='text'>
                                                            <div className='title'>
                                                                {add.vehiclename}
                                                            </div>
                                                        </div>
                                                        <div onClick={() => nextClicked(add)} className="arrow-icon">
                                                            <BsArrowRight size={30} style={{ color: 'white' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                } */}
                            </div >
                            {
                                InprogressAdds.length > 0 &&
                                <div style={{ marginTop: 20 }}>
                                    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <h2 className="headertext_2  mt3 mb4" style={{ marginLeft: 10 }}>
                                            Inaktive Anzeigen
                                        </h2>
                                        <button className='btn_7days' style={{ color: 'rgb(39,103,352)' }} onClick={() => { gotoAdsList('inactive') }}>Alle ansehen</button>
                                    </div>


                                    <div className='adds_section_cards'>
                                        {InprogressAdds.slice(0, 3).map(add => (
                                            <div className='card_content' >
                                                <Row className='row_add' id={`${add.id}`} onClick={() => {
                                                    setAddDetails({})
                                                    navigate('/adcreate', { state: { id: add.id } })
                                                }}>
                                                    <Col md={3} xs={3} xl={2} xxl={2} lg={3} sm={3} className='col_add' style={{ borderRadius: 10, display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 10, justifyContent: 'center' }}>
                                                        <img style={{ width: '100%', objectFit: 'contain', borderRadius: 10, maxHeight: 100, borderRadius: 10 }} src={add.hero_image_2k !== null ? add.hero_image_2k : require('../../assets/add_placeholder.jpeg')} />
                                                        {((add.publish_status === 'inprogress' || add.publish_status === 'unpublished') && add.status === 'completed' && add.vehicle_basic_technical_data_status === 'completed' && add.vehicle_ad_feature_technical_data_status === 'completed') &&
                                                            <button className="publish_btn" onClick={(e) => {
                                                                e.stopPropagation();
                                                                handlePublishClick(add.publish_status, add.id, add)
                                                            }}> <span>{add.publish_status === 'inprogress' ? 'Veröffentlichen' : add.publish_status === 'unpublished' ? 'Erneut veröffentlichen' : ''}</span></button>
                                                        }
                                                    </Col>
                                                    <Col md={8} xxl={9} xl={9} lg={8} sm={8} xs={8} className='col_add' style={{ marginLeft: 5, marginRight: 5 }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>
                                                            <span className='add_name'>{add.vehicle_name}</span>
                                                            {(add.updated_datetime !== null) && <span className='add_date' style={{ marginLeft: 10, marginRight: 10 }}>{`${formatDateTime(add.updated_datetime)}`}</span>}
                                                            {/* <span className='add_date' style={{ marginLeft: 10 }}>01.02 - 30.03</span> */}
                                                            <span className='add_name' style={{ marginRight: 10 }}>{formatCurrency(add.price, add.currency)}</span>
                                                        </div>
                                                        <Row style={{ marginBottom: 10, marginLeft: 10, marginRight: 10 }}>
                                                            <Col xxl={3} xl={5} lg={5} md={5} xs={5} className='col_add'>
                                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                    <span className='add_name'>{add.ad_analytics_data.impressions}</span>
                                                                    <span className='add_date'>Impressionen</span>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3} xl={5} md={5} lg={5} xs={5} className='col_add'>
                                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                    <span className='add_name'>{add.ad_analytics_data.active_users}</span>
                                                                    <span className='add_date'>Aktive Benutzer</span>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={5} xl={9} md={7} lg={8} xs={10} className='col_add'>
                                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                    <span className='add_name'>{Math.round(add.ad_analytics_data.average_session_duration)}</span>
                                                                    <span className='add_date'>Durchschnittliche Sitzungsdauer</span>
                                                                    <span style={{ fontSize: 13 }}>(in Sekunden)</span>
                                                                </div>
                                                            </Col>
                                                            {/* <Col md={3} xs={3} className='col_add'>
                                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                    <span className='add_name'>--</span>
                                                                    <span className='add_date'>Nachrichten</span>
                                                                </div>
                                                            </Col> */}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row style={{ paddingLeft: 10, paddingBottom: 10 }}>
                                                    <Col>
                                                        {((add.publish_status === 'inprogress' || add.publish_status === 'unpublished') && (add.status === 'incomplete' || add.vehicle_basic_technical_data_status === 'incomplete' || add.vehicle_ad_feature_technical_data_status === 'incomplete')) &&
                                                            <span className='incompletetextdash'>Unvollständige Daten, bitte vervollständigen Sie diese.</span>
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                    </div>
                                    <button className="add_btn2" onClick={createAddBtnClick}>+ Anzeige erstellen</button>
                                </div>

                            }

                        </Col >
                    </Row >
                    {/* <Row style={{ marginTop: '5vmin' }}>
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <h2 className="headertext_2  mt3 mb4">
                                        Profil
                                    </h2>
                                </Col>
                                <Col md={4}>
                                    <button className='btn_7days'>Bearbeiten | Besuchen</button>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '2vmin' }}>
                                <Col md={11}>
                                    <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/adsdashboardprofile.png"})`, backgroundSize: 'contain', backgroundPosition: 'center', aspectRatio: '1/0.8', }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}
                </div >
                {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 20 }}>
                    <button className="enquire_btn" onClick={createAddBtnClick}>Erstellen Hinzufügen</button>
                </div> */}
                {
                    showalert &&
                    <SuccessAlert message={adsmessage} onClose={() => { setShowAlert(false) }}></SuccessAlert>
                }
            </div >
        </>
    )
}

export default DashBoard;