import React, { useState, useEffect, useContext, useRef } from 'react';
import '../../app.css'
import { useParams } from 'react-router-dom';
import '../Search/Search.css'
import '../UserAds/UserAds.css'
import { useNavigate } from 'react-router-dom';
import { URLS, usePageTracking } from '../../utilities/constants';
import { DataContext } from '../../utilities/DataContext';
import axios from 'axios';
import CountryFlag from '../Search/CountryFlag';
import { debounce } from 'lodash';
import ReactGA from 'react-ga4';
import Cookies from 'js-cookie';
import { useAnalytics } from '../../utilities/AnalyticsContext';

const UserAds = () => {
    const navigate = useNavigate();
    const { markTitleUpdated } = useAnalytics()
    let { companyName } = useParams();
    const [addsList, setAddsList] = useState([])
    const { token, setPageTitleAvailable } = useContext(DataContext);

    const pageTitle = `Suchergebnisse Campervans zu ${companyName}`;
    const previousTitleRef = useRef();

    useEffect(() => {
        document.title = `Suchergebnisse Campervans zu ${companyName}`;
        // markTitleUpdated(pageTitle)
        window.parent.postMessage({ iframeLoad: true }, '*');
    }, [])


    const sendAnalyticsEvent = debounce((title) => {
        if (previousTitleRef.current !== title) {
            console.log('Sending pageview event to Google Analytics:', title);
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title });
            setPageTitleAvailable(true)
            previousTitleRef.current = title;
        }
    }, 500);


    const debouncedTrackPageTitleChange = debounce((title) => {
        if (previousTitleRef.current !== title && window.trackPageTitleChange) {
            window.trackPageTitleChange(title);
        }
        // Update the previous title reference
        previousTitleRef.current = title;
    }, 500);
    useEffect(() => {
        callAddsListApi(companyName)
        // Post the height of the addsection_cards div
        // Post the height of the addsection_cards div
        setTimeout(() => {

            const addSection = document.querySelector('.addsection_cards');
            console.log('addSection', addSection)
            if (addSection) {
                const height = addSection.scrollHeight;
                window.parent.postMessage({ action: 'setHeight', height }, '*');
            }
        }, 1000);

    }, [])


    const adClicked = (item) => {
        const detailsUrl = `https://app.campervanspot.com/ad/${item.id}`;
        window.open(detailsUrl, '_blank');
    }


    const formatMilageNumber = (value) => {
        return new Intl.NumberFormat('de-DE').format(value);
    }



    const formatCurrency = (value, currency) => {
        console.log('currency', currency)
        if (value !== '' && currency !== '') {
            let locale = 'de-DE'; // German locale for both EUR and CHF
            let formattedValue = new Intl.NumberFormat(locale, {
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(value);
            return `${formattedValue} ${currency.toUpperCase()}`;
        } else {
            return ''
        }

    };

    const callAddsListApi = async (endpoint) => {
        const url = `${URLS.BASE_URL}${URLS.USER_ADS_LIST}${endpoint}`
        console.log('adurl', url)
        await axios.get(url, { headers: { 'Authorization': token } })
            .then((response) => {
                if (response?.data.status === 1) {
                    const data = response.data.data
                    console.log('data', data)
                    setAddsList(data);
                } else {
                    console.log('response', response);
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                console.log('error', error);
                alert(error.response.data.message);
            });
    }


    return (


        <div className='addsection_cards'>
            {addsList.map((item, index) => (
                <div key={item.id} onClick={() => adClicked(item)} className='adimage' style={{ backgroundImage: item.hero_image_url_2k != null ? `url(${item.hero_image_url_2k})` : null, alignItems: 'flex-end', display: 'flex', justifyContent: 'flex-end' }}>
                    {/* <div className='adoverlay' /> */}
                    <div className='adddata' style={{ width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', paddingLeft: 20, width: '100%', paddingTop: 5, paddingBottom: 5 }}>
                            <CountryFlag countryCode={item.country_code} />
                            {/* <h1 className='adtitle' style={{ fontSize: 17, fontWeight: 400, color: 'white', marginBottom: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.vehicle_name !== '' ? item.vehicle_name : '--'}</h1> */}
                            <h1 style={{ fontSize: 17, fontWeight: 400, color: 'white', marginBottom: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.vehicle_name !== '' ? item.vehicle_name : '--'}</h1>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div>
                                <div>
                                    <img src={require('../../assets/seat.png')} style={{ height: 15, width: 15 }} alt='seat' />
                                    <span className='addetails'>{item.no_of_seats !== null && item.no_of_seats !== 0 ? item.no_of_seats : '--'}</span>
                                </div>
                                <div>
                                    <img src={require('../../assets/bed.png')} style={{ height: 15, width: 15 }} alt='bed' />
                                    <span className='addetails'>{item.total_no_of_beds !== null && item.total_no_of_beds !== 0 ? item.total_no_of_beds : '--'}</span>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div>

                                    <div md={3}>
                                        <span className='addetails'>{item.year_of_manufacture !== null ? item.year_of_manufacture : '--'}</span> </div>
                                    <div md={9}><span className='addetails'>{item.mileage_km !== null && item.mileage_km !== 0 ? `${formatMilageNumber(item.mileage_km)} km` : '--'}</span></div>
                                </div>
                                <div>
                                    <span className='addetails'>{(item.price !== null && item.currency !== null) ? formatCurrency(item.price, item.currency) : '--'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>



    );
};

export default UserAds;